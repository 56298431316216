import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import axios from "../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import TermsModal from "../components/TermsModal"; // تأكد من المسار الصحيح
import ReCAPTCHA from "react-google-recaptcha"; // إضافة مكتبة reCAPTCHA
import "../i18n";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null); // حالة reCAPTCHA

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return passwordRegex.test(password);
  };

  const validateUsername = (username) => {
    const usernameRegex = /^[a-zA-Z0-9]{6,}$/;
    return usernameRegex.test(username);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateUsername(username)) {
      setError(
        t(
          "يجب أن يتكون اسم المستخدم من 6 أحرف على الأقل وأن يحتوي على حروف وأرقام إنجليزية فقط"
        )
      );
      return;
    }

    if (!validateEmail(email)) {
      setError(t("البريد الإلكتروني غير صالح"));
      return;
    }

    if (!validatePassword(password)) {
      setError(
        t(
          "كلمة المرور يجب أن تحتوي على 8 أحرف على الأقل، مع حروف كبيرة وصغيرة وأرقام"
        )
      );
      return;
    }

    if (password !== confirmPassword) {
      setError(t("تأكيد كلمة المرور لا يتطابق مع كلمة المرور"));
      return;
    }

    if (!agreeToTerms) {
      setError(t("يجب أن توافق على اتفاقية وقوانين السيرفر قبل التسجيل."));
      return;
    }

    if (!captchaValue) {
      // التحقق من reCAPTCHA
      setError(t("يرجى التحقق من أنك لست روبوتًا."));
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    const currentLanguage = localStorage.getItem("i18nextLng") || "en";

    try {
      await axios.post("/api/register", {
        account: username,
        email: email,
        password: password,
        language: currentLanguage,
        recaptchaValue: captchaValue, // إرسال قيمة reCAPTCHA إلى الخادم
      });

      setSuccess(t("تم تسجيل الحساب بنجاح"));
      setTimeout(() => {
        navigate("/download"); // التوجيه إلى صفحة /download بعد نجاح التسجيل
      }, 2000);
    } catch (err) {
      console.error("Error submitting registration: ", err.message);
      if (err.response && err.response.status === 400) {
        setError(
          typeof err.response.data === "string"
            ? err.response.data
            : err.response.data.message || JSON.stringify(err.response.data)
        );
      } else {
        setError(t("خطأ في تسجيل الحساب"));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Container maxWidth="xs" sx={{ mt: 7, mb: 4 }}>
      <Box
        sx={{
          textAlign: "center",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "rgba(255, 255, 255, 0.8)", // تعتيم الخلفية
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t("تسجيل حساب جديد")}
        </Typography>
        {error && (
          <Alert severity="error">
            {typeof error === "string"
              ? error
              : error.message || JSON.stringify(error)}
          </Alert>
        )}
        {success && <Alert severity="success">{success}</Alert>}
        {loading && <CircularProgress />}
        <form onSubmit={handleSubmit}>
          <TextField
            label={t("اسم المستخدم")}
            variant="outlined"
            fullWidth
            margin="normal"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ fontSize: "1.2rem" }}
            InputLabelProps={{
              style: { fontSize: "1.2rem", color: "#4B0082" },
            }}
          />
          <TextField
            label={t("البريد الإلكتروني")}
            variant="outlined"
            type="email"
            fullWidth
            margin="normal"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ fontSize: "1.2rem" }}
            InputLabelProps={{
              style: { fontSize: "1.2rem", color: "#4B0082" },
            }}
          />
          <TextField
            label={t("كلمة المرور")}
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ fontSize: "1.2rem" }}
            InputLabelProps={{
              style: { fontSize: "1.2rem", color: "#4B0082" },
            }}
          />
          <TextField
            label={t("تأكيد كلمة المرور")}
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{ fontSize: "1.2rem" }}
            InputLabelProps={{
              style: { fontSize: "1.2rem", color: "#4B0082" },
            }}
          />

          {/* إضافة مكون reCAPTCHA */}
          <ReCAPTCHA
            //المحلي
            // sitekey="6Ld_S2AqAAAAAHb4m7ptd9pLe-75ZFBIYJb35edC"
            //السيرفر
            // sitekey="6LdqSGAqAAAAAEAR7tFioMkXGTgSGamv6BB7yLEm"
              //الاستضافة
            sitekey="6Lch0X8qAAAAAPbeiCZaxXUeyEIYpcGDTm3MZcKr"
            onChange={(value) => setCaptchaValue(value)} // تخزين قيمة reCAPTCHA
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={agreeToTerms}
                onChange={(e) => setAgreeToTerms(e.target.checked)}
                color="primary"
              />
            }
            label={
              <span>
                {t("عند الضغط على زر التسجيل، أنت توافق على")}{" "}
                <button
                  onClick={handleOpenModal}
                  style={{
                    color: "#4B0082",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {t("اتفاقية وقوانين السيرفر")}
                </button>
              </span>
            }
          />

          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            type="submit"
            disabled={loading}
          >
            {loading ? t("جاري تسجيل..") : t("تسجيل")}
          </Button>
        </form>
      </Box>

      {/* Modal for Terms and Policies */}
      <TermsModal open={openModal} onClose={handleCloseModal} />
    </Container>
  );
};

export default Register;
