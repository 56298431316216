import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "../utils/axiosConfig";

const News = () => {
  const [news, setNews] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);

  const [editedTitle, setEditedTitle] = useState("");
  const [editedDetails, setEditedDetails] = useState("");
  const [editedBody, setEditedBody] = useState("");

  // حالات إنشاء خبر جديد
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newDetails, setNewDetails] = useState("");
  const [newBody, setNewBody] = useState("");

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get("/api/news");
        setNews(response.data);
      } catch (error) {
        setSnackbarMessage("فشل تحميل الأخبار.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      }
    };

    fetchNews();
  }, []);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleOpenEditDialog = (newsItem) => {
    setSelectedNews(newsItem);
    setEditedTitle(newsItem.title);
    setEditedDetails(newsItem.details);
    setEditedBody(newsItem.body);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedNews(null);
  };

  const handleEditSubmit = async () => {
    if (selectedNews) {
      try {
        const response = await axios.put(`/api/news/${selectedNews.id}`, {
          title: editedTitle,
          details: editedDetails,
          body: editedBody,
        });
        setSnackbarMessage(response.data);
        setSnackbarSeverity("success");
        setShowSnackbar(true);
        // تحديث قائمة الأخبار بعد تعديل الخبر
        const updatedNews = await axios.get("/api/news");
        setNews(updatedNews.data);
      } catch (error) {
        setSnackbarMessage("فشل تعديل الخبر.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      }
    }
    handleCloseEditDialog();
  };

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    setNewTitle("");
    setNewDetails("");
    setNewBody("");
  };

  const handleCreateSubmit = async () => {
    try {
      const response = await axios.post("/api/news", {
        title: newTitle,
        details: newDetails,
        body: newBody,
      });
      setSnackbarMessage(response.data);
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      // تحديث القائمة الأخبار بعد إنشاء خبر جديد
      const updatedNews = await axios.get("/api/news");
      setNews(updatedNews.data);
    } catch (error) {
      setSnackbarMessage("فشل إنشاء الخبر.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
    handleCloseCreateDialog();
  };

  const handleDeleteItem = async (itemId) => {
    try {
      await axios.delete(`/api/news/${itemId}`);
      setSnackbarMessage("تم حذف الخبر بنجاح.");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      // تحديث قائمة الأخبار بعد الحذف
      const updatedNews = await axios.get("/api/news");
      setNews(updatedNews.data);
    } catch (error) {
      setSnackbarMessage("فشل حذف الخبر.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };

  return (
    <Container maxWidth="lg" style={{ direction: "rtl" }}>
      <Box
        sx={{ p: 3, bgcolor: "#ffffff", borderRadius: 1, boxShadow: 1, mb: 2 }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" gutterBottom>
            الأخبار
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCreateDialog}
            sx={{ mb: 2 }}
          >
            إنشاء خبر جديد
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: "#5B0052" }}>
              <TableRow>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  ID
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  العنوان
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الوصف
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  تاريخ الإنشاء
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  عرض الخبر
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الإجراءات
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {news.map((item) => (
                <TableRow key={item.id} style={{ cursor: "pointer" }}>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.id}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.title}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.details}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {new Date(item.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleOpenEditDialog(item)}
                    >
                      عرض الخبر كامل
                    </Button>
                  </TableCell>
                  <TableCell
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      color="info"
                      variant="contained"
                      onClick={() => handleOpenEditDialog(item)}
                    >
                      تعديل
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => handleDeleteItem(item.id)}
                    >
                      حذف
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Dialog for editing news */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>تعديل الخبر</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="العنوان"
            type="text"
            fullWidth
            variant="outlined"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="الوصف"
            type="text"
            fullWidth
            variant="outlined"
            value={editedDetails}
            onChange={(e) => setEditedDetails(e.target.value)}
          />
          <TextField
            margin="dense"
            label="نص الخبر"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={editedBody}
            onChange={(e) => setEditedBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditSubmit} color="primary">
            تعديل الخبر
          </Button>
          <Button onClick={handleCloseEditDialog} color="secondary">
            إغلاق
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for creating new news */}
      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
        <DialogTitle>إنشاء خبر جديد</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="العنوان"
            type="text"
            fullWidth
            variant="outlined"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="الوصف"
            type="text"
            fullWidth
            variant="outlined"
            value={newDetails}
            onChange={(e) => setNewDetails(e.target.value)}
          />
          <TextField
            margin="dense"
            label="نص الخبر"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={newBody}
            onChange={(e) => setNewBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateSubmit} color="primary">
            إنشاء خبر
          </Button>
          <Button onClick={handleCloseCreateDialog} color="secondary">
            إغلاق
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default News;
