import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Container,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Players from "./Players";
import Shop from "./Shop";
import Links from "./Accounts";
import News from "./News";
import Statistics from "./Statistics";
import Items from "./Items";
import ChargeManagement from "./ChargeManagement";
import Logs from "./Logs"; // استيراد مكون السجلات
import CouponManagement from "./CouponManagement"; // استيراد مكون إدارة الكوبونات
import axios from "../utils/axiosConfig";

const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState("statistics");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        navigate("/");
        return;
      }

      try {
        const response = await axios.get("/api/checkAuth", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLoading(false);

        if (response.data.permission !== 500) {
          navigate("/"); // إعادة التوجيه إذا لم تكن الصلاحيات 500
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        navigate("/");
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "players":
        return <Players />;
      case "shop":
        return <Shop />;
      case "accounts":
        return <Links />;
      case "news":
        return <News />;
      case "statistics":
        return <Statistics />;
      case "items":
        return <Items />;
      case "charge":
        return <ChargeManagement />;
      case "coupon": // إضافة حالة الكوبونات
        return <CouponManagement />;
      case "logs":
        return <Logs />;
      default:
        return <Statistics />;
    }
  };

  if (loading) {
    return (
      <Container
        maxWidth="lg"
        style={{ direction: "rtl", textAlign: "center", marginTop: "20%" }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          جاري تحميل البيانات...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" style={{ direction: "rtl" }} sx={{ mt: 4 }}>
      <Paper
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mb: 2,
          backgroundColor: "#1976d2",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="tabs"
          textColor="inherit"
          indicatorColor="secondary"
        >
          <Tab
            value="statistics"
            label="الإحصائيات"
            sx={{ color: "#ffffff" }}
          />
          <Tab value="players" label="الشخصيات" sx={{ color: "#ffffff" }} />
          <Tab value="accounts" label="الحسابات" sx={{ color: "#ffffff" }} />
          <Tab value="items" label="العناصر" sx={{ color: "#ffffff" }} />
          <Tab value="shop" label="المتجر" sx={{ color: "#ffffff" }} />
          <Tab value="news" label="الأخبار" sx={{ color: "#ffffff" }} />
          <Tab value="charge" label="إدارة الشحنات" sx={{ color: "#ffffff" }} />
          <Tab
            value="coupon"
            label="إدارة الكوبونات"
            sx={{ color: "#ffffff" }}
          />{" "}
          {/* علامة تبويب إدارة الكوبونات */}
          <Tab value="logs" label="السجلات" sx={{ color: "#ffffff" }} />{" "}
          {/* علامة السجلات */}
        </Tabs>
      </Paper>

      <Box sx={{ p: 3, bgcolor: "#f5f5f5", borderRadius: 1 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          {selectedTab === "statistics" && "الإحصائيات"}
          {selectedTab === "players" && "إدارة الشخصيات"}
          {selectedTab === "shop" && "المتجر"}
          {selectedTab === "accounts" && "إدارة الحسابات"}
          {selectedTab === "news" && "الأخبار"}
          {selectedTab === "items" && "العناصر"}
          {selectedTab === "charge" && "إدارة الشحنات"}
          {selectedTab === "coupon" && "إدارة الكوبونات"}{" "}
          {/* عنوان إدارة الكوبونات */}
          {selectedTab === "logs" && "السجلات"} {/* عنوان للسجلات */}
        </Typography>
        {renderContent()}
      </Box>
    </Container>
  );
};

export default Dashboard;
