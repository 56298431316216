// PaidItemLogsTable.js
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const PaidItemLogsTable = ({ logs }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>الرقم التعريفي</TableCell>
            <TableCell>رمز العنصر</TableCell>
            <TableCell>عدد العناصر</TableCell>
            <TableCell>الإجمالي</TableCell>
            <TableCell>رقم الحساب</TableCell>
            <TableCell>الاعتماد القديم</TableCell>
            <TableCell>الاعتماد الجديد</TableCell>
            <TableCell>عنوان IP</TableCell>
            <TableCell>المتصفح</TableCell>
            <TableCell>تاريخ الشراء</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((log) => (
            <TableRow key={log.id}>
              <TableCell>{log.id}</TableCell>
              <TableCell>{log.item_code}</TableCell>
              <TableCell>{log.item_count}</TableCell>
              <TableCell>{log.item_total_price}</TableCell>
              <TableCell>{log.account_id}</TableCell>
              <TableCell>{log.account_old_credit}</TableCell>
              <TableCell>{log.account_new_credit}</TableCell>
              <TableCell>{log.ip_address}</TableCell>
              <TableCell>{log.browser}</TableCell>
              <TableCell>{new Date(log.buy_time).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaidItemLogsTable;
