import React, { useEffect, useState } from "react";
import axios from "../utils/axiosConfig";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Box,
} from "@mui/material";
import "../i18n";
import { useTranslation } from "react-i18next";


const VerifyEmail = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("loading"); // loading, success, error, info
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verifyAccount = async () => {
      try {
        const response = await axios.get(
          `/api/verify/${token}`
        );
        const { status, message } = response.data;

        setStatus(status);
        setMessage(message);

        if (status === "success") {
          // إعادة توجيه المستخدم بعد التفعيل
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else if (status === "info") {
          // إعادة توجيه المستخدم بعد عرض الرسالة
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      } catch (error) {
        console.error("Verification failed:", error.message);
        setStatus("error");
        setMessage(t("فشل التحقق، يرجى المحاولة مرة أخرى"));
      }
    };

    verifyAccount();
  }, [token, navigate,t]);

  return (
    <Container maxWidth="xs" sx={{ height: "56vh", mt: 8, mb: 4 }}>
      <Box
        sx={{
          textAlign: "center",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        }}
      >
        {status === "loading" && (
          <>
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              {t("جاري التحقق من حسابك...")}
            </Typography>
          </>
        )}
        {status === "success" && (
          <>
            <Typography variant="h4" component="h1" gutterBottom>
              {message}
            </Typography>
            <Typography variant="body1" paragraph>
             {t("شكراً لتفعيل حسابك. سيتم نقلك إلى الصفحة الرئيسية قريباً")}
            </Typography>
          </>
        )}
        {status === "info" && (
          <>
            <Typography variant="h4" component="h1" gutterBottom>
              {message}
            </Typography>
            <Typography variant="body1" paragraph>
              {t("سيتم نقلك إلى الصفحة الرئيسية قريباً")}
            </Typography>
          </>
        )}
        {status === "error" && (
          <>
            <Alert severity="error">{message}</Alert>
          </>
        )}
      </Box>
    </Container>
  );
};

export default VerifyEmail;
