import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "../i18n";
import { useTranslation } from "react-i18next";

const Category = ({ categoryName, items, onAddToCart }) => {
  const [quantity, setQuantity] = useState({});
  const [selectedItem, setSelectedItem] = useState(null); // لحفظ العنصر المحدد لعرض المودال

  const handleQuantityChange = (itemId, value) => {
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [itemId]: value,
    }));
  };

  const handleAddToCart = (item) => {
    onAddToCart({ ...item, quantity: parseInt(quantity[item.id]) || 1 });
  };

  const handleOpenModal = (item) => {
    setSelectedItem(item); // حفظ العنصر المحدد عند الضغط
  };

  const handleCloseModal = () => {
    setSelectedItem(null); // إغلاق المودال
  };
  const { t } = useTranslation();
  return (
    <Box mb={4} textAlign="center">
      <Typography
        variant="h4"
        sx={{
          background: "#f5f5f5",
          borderRadius: "8px",
          padding: "8px",
          fontWeight: 600,
          boxShadow: 2,
        }}
        gutterBottom
      >
        {categoryName}
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
        {items.map((item) => (
          <Card
            key={item.id}
            sx={{
              maxWidth: 220,
              bgcolor: "#ffffff",
              borderRadius: 8,
              boxShadow: 2,
              margin: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <Box
              component="img"
              src={`/images/shop/${item.item_icon}`}
              alt={t(item.item_name)}
              sx={{
                width: 40,
                height: 40,
                objectFit: "contain",
                margin: "auto",
                mt: 1,
              }}
            />
            <CardContent sx={{ p: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                {t(item.item_name)}
              </Typography>

              {/* زر لفتح المودال وعرض الوصف */}
              <Button
                variant="text"
                color="primary"
                onClick={() => handleOpenModal(item)}
                sx={{ mb: 1, textDecoration: "underline" }}
              >
                {t("التفاصيل")}
              </Button>

              <Divider sx={{ mb: 1 }} />
              <Typography variant="body2" color="primary" sx={{ mb: 2 }}>
                {item.item_point} {t("النقاط")}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <TextField
                  type="number"
                  value={quantity[item.id] || 1} // تعيين القيمة الافتراضية إلى 1
                  onChange={(e) =>
                    handleQuantityChange(item.id, e.target.value)
                  }
                  sx={{ width: 80, mr: 1 }}
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                  placeholder={t("الكمية")}
                  size="small"
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAddToCart(item)}
                  sx={{ fontSize: "0.75rem", height: 35, fontWeight: "bold" }}
                >
                  {t("أضف إلى السلة")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* المودال لعرض تفاصيل الوصف */}
      {selectedItem && (
        <Dialog open={Boolean(selectedItem)} onClose={handleCloseModal}>
          <DialogTitle textAlign={"center"}>
            {" "}
            {t(selectedItem.item_name)}
          </DialogTitle>
          <DialogContent>
            <Typography textAlign={"center"}>
              {t(selectedItem.item_desc)}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              {t("إغلاق")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Category;
