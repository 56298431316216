import React, { useState, useEffect } from "react";
import { Container, Box, Snackbar, Alert } from "@mui/material";
import axios from "../utils/axiosConfig";
import Navbar from "./Navbar";
import Category from "./Category";
import Cart from "./Cart";
import "../i18n";
import { useTranslation } from "react-i18next";

const StorePage = () => {
  const { t } = useTranslation();
  const [cart, setCart] = useState([]);
  const [cartOpen, setCartOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [items, setItems] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  // جلب البيانات من /api/shop عند تحميل الصفحة
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get("/api/shop");
        setItems(response.data); // حفظ العناصر في الحالة
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };

    fetchItems();

    // Retrieve cart from localStorage
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  const handleAddToCart = (item) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (cartItem) => cartItem.id === item.id
      );

      let updatedCart;
      if (existingItemIndex > -1) {
        updatedCart = [...prevCart];
        updatedCart[existingItemIndex].quantity += parseInt(
          item.quantity || 1,
          10
        );
      } else {
        updatedCart = [
          ...prevCart,
          {
            ...item,
            quantity: parseInt(item.quantity || 1, 10),
            price: item.item_point,
            image: item.item_image, // إضافة الصورة هنا
          },
        ];
      }

      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });

    setShowSnackbar(true);
  };

  // دالة لتحديث كمية عنصر في السلة
  const handleUpdateQuantity = (itemId, newQuantity) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.map((item) =>
        item.id === itemId ? { ...item, quantity: newQuantity } : item
      );

      // حفظ السلة المحدثة في localStorage
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const handleCartOpen = () => setCartOpen(true);
  const handleCartClose = () => setCartOpen(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleRemoveItem = (itemId) => {
    const updatedCart = cart.filter((item) => item.id !== itemId);

    // Save updated cart to localStorage
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCart(updatedCart);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const categories = [
    {
      name: t("تخفيضات"),
      items: items.filter((item) => item.item_categorie === 1),
    },
    {
      name: t("مستلزمات يومية"),
      items: items.filter((item) => item.item_categorie === 2),
    },
    {
      name: t("مستلزمات تطوير"),
      items: items.filter((item) => item.item_categorie === 3),
    },
    {
      name: t("لوازم خاصة"),
      items: items.filter((item) => item.item_categorie === 4),
    },
    {
      name: t("زينة"),
      items: items.filter((item) => item.item_categorie === 5),
    },
    {
      name: t("النقاط"),
      items: items.filter((item) => item.item_categorie === 6),
    },
    {
      name: t("المرافقين"),
      items: items.filter((item) => item.item_categorie === 7),
    },
  ];

  return (
    <div dir="rtl">
      <Container style={{marginBottom:"250px"}}>
        <Navbar
          categories={categories}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          handleCartOpen={handleCartOpen}
        />

        <Box mt={2}>
          {categories.map(
            (category, index) =>
              selectedTab === index && (
                <Category
                  key={category.name}
                  categoryName={category.name}
                  items={category.items}
                  onAddToCart={handleAddToCart}
                />
              )
          )}
        </Box>

        <Cart
          cart={cart}
          open={cartOpen}
          onClose={handleCartClose}
          onRemoveItem={handleRemoveItem}
          onUpdateQuantity={handleUpdateQuantity} // تمرير دالة تحديث الكمية
        />

        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            {t("تمت إضافة العنصر إلى السلة بنجاح!")}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default StorePage;
