// SuccessPage.js
import React from "react";
import { Container, Typography, Button, Box, Paper } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const points = new URLSearchParams(location.search).get("points");
  const newPoints = new URLSearchParams(location.search).get("newPoints");

  const handleGoToStore = () => {
    navigate("/shop");
  };

  const handleGoToProfile = () => {
    navigate("/profile");
  };

  return (
    <Container maxWidth="sm" style={{ padding: "20px", height: "90%" }}>
      <Paper elevation={3} style={{ padding: "20px", borderRadius: "8px" }}>
        <Typography variant="h4" gutterBottom align="center" color="primary">
          Payment Successful!
        </Typography>
        <Typography variant="body1" gutterBottom align="center">
          Your payment has been processed successfully. Thank you for your
          purchase!
        </Typography>
        <Typography variant="body1" gutterBottom align="center">
          Points added: <strong>{points}</strong>
        </Typography>
        <Typography variant="body1" gutterBottom align="center">
          Your new points balance is: <strong>{newPoints}</strong>
        </Typography>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoToStore}
            style={{ marginRight: "10px" }}
          >
            Go to Store
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleGoToProfile}
          >
            Go to Profile
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default SuccessPage;
