import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormControl} from "@mui/material";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("ar");

  useEffect(() => {
    const savedLanguage = localStorage.getItem("i18nextLng") || "ar";
    setCurrentLanguage(savedLanguage);
  }, []);

  const changeLanguage = (event) => {
    const lng = event.target.value;
    setCurrentLanguage(lng);
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  return (
    <FormControl
      variant="outlined"
      style={{  minWidth: 120 }}
    >
      <Select
        labelId="language-select-label"
        style={{color: "#f7a809", border: " 1px #f7a809 solid" }} 
        value={currentLanguage}
        onChange={changeLanguage}
        MenuProps={{
          PaperProps: {
            style: {
              color: "white",
            },
          },
        }}
      >
        <MenuItem value="en" >
          <img
            src={"/images/united-kingdom-flag-icon.png"}
            alt="English"
            style={{ width: "20px", margin: "0 8px" }}
          />
           English 
        </MenuItem>
        <MenuItem value="ar" >
          <img
            src={"/images/Arabic-Language-Flag.svg.png"}
            alt="Arabic"
            style={{ width: "20px", margin: "0 8px" }}
          />
          العربية
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcher;
