// src/components/Statistics.js
import React, { useEffect, useState } from "react";
import axios from "../utils/axiosConfig";
import { Typography, Box, Grid, Paper } from "@mui/material";
import { AccountCircle, People } from "@mui/icons-material";

const Statistics = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/stats");
        setData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading)
    return <Typography variant="h5">جاري تحميل البيانات...</Typography>;
  if (error)
    return (
      <Typography variant="h5" color="error">
        حدث خطأ: {error}
      </Typography>
    );

  return (
    <Box sx={{ p: 3, bgcolor: "#ffffff", borderRadius: 1, boxShadow: 3 }}>
      <Typography
        variant="h4"
        sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}
      >
        الإحصائيات
      </Typography>
      <Grid container spacing={3}>
        {/* قسم الشخصيات */}
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            sx={{ p: 4, bgcolor: "#e0f7fa", borderRadius: 2 }}
          >
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <People sx={{ mr: 1 }} /> إحصائيات الشخصيات
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              إجمالي الشخصيات:{" "}
              <strong>{data.characterStats.totalCharacters}</strong>
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              عدد الشخصيات المتصلة:{" "}
              <strong>{data.characterStats.onlineCharacters}</strong>
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              إجمالي الذهب: <strong>{data.characterStats.totalGold}</strong>
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              إجمالي نقاط التحدي:{" "}
              <strong>{data.characterStats.totalHuntaholicPoints}</strong>
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              إجمالي نقاط الميدان:{" "}
              <strong>{data.characterStats.totalArenaPoints}</strong>
            </Typography>
          </Paper>
        </Grid>

        {/* قسم الحسابات */}
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            sx={{ p: 4, bgcolor: "#e0f7fa", borderRadius: 2 }}
          >
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AccountCircle sx={{ mr: 1 }} /> إحصائيات الحسابات
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              إجمالي الحسابات:{" "}
              <strong>{data.accountStats.totalAccounts}</strong>
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              عدد الحسابات النشطة:{" "}
              <strong>{data.accountStats.activeAccounts}</strong>
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              عدد الحسابات المحظورة:{" "}
              <strong>{data.accountStats.blockedAccounts}</strong>
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              إجمالي النقاط: <strong>{data.accountStats.totalPoints}</strong>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Statistics;
