import React from "react";
import { Box, Typography, Button } from "@mui/material";
import "../i18n";
import { useTranslation } from "react-i18next";
const Discord = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `url("/images/image1.jpg")`, // تأكد من أن المسار صحيح
        backgroundSize: "cover",
        backgroundPosition: "top",
        width: "70%",
        maxWidth: "600px",
        margin: "auto",
        padding: "40px",
        borderRadius: "16px",
        textAlign: "center",
        color: "#fff",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.7)", // لون التظليل مع الشفافية
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <Typography variant="h4" component="div" gutterBottom>
          {t("انضم إلى مجتمعنا على Discord!")}
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
          {t(
            " نحن نرحب بالجميع في مجتمعنا المتنوع والمليء بالنشاطات الممتعة. لا تفوت فرصة التعرف على أصدقاء جدد والمشاركة في المناقشات المثيرة!"
          )}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href="https://discord.gg/YBAzVcn4FU"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            mt: 2,
            fontSize: "16px",
            padding: "10px 20px",
          }}
        >
          {t("انضم الآن")}
        </Button>
      </Box>
    </Box>
  );
};

export default Discord;
