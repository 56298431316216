import React from "react";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom"; // استيراد useNavigate
import { useAuth } from "./AuthContext"; // استيراد سياق المصادقة
import axios from "../utils/axiosConfig"; // تأكد من أن axios يحتوي على إعدادات الكوكيز
import LanguageSwitcher from "./LanguageSwitcher"; // استيراد مكون LanguageSwitcher
import "../i18n";
import { useTranslation } from "react-i18next";

function ResponsiveAppBar() {
  const { t } = useTranslation();
  const pages = [
    { name: t("الصفحة الرئيسية"), path: "/" },
    { name: t("التحميل"), path: "/download" },
    { name: t("ترتيب اللاعبين"), path: "/ranking" },
    { name: t("آخر الأخبار"), path: "/news" },
    { name: t("المتجر"), path: "/shop" },
    { name: t("الشحن"), path: "/charge" },
  ];

  const settings = [
    { key: "account", label: t("معلومات الحساب") },
    { key: "settings", label: t("الإعدادات") },
    { key: "RedeemCoupon", label: t("الكوبونات") },
    { key: "logout", label: t("تسجيل الخروج") },
  ];

  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    // الحصول على اللغة من localStorage
    const storedLanguage = localStorage.getItem("i18nextLng");

    // تعيين الاتجاه بناءً على اللغة
    if (storedLanguage === "ar") {
      setDirection("rtl");
    } else {
      setDirection("ltr");
    }
  }, []);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { isAuthenticated, logout } = useAuth(); // استخدام سياق المصادقة
  const navigate = useNavigate(); // استخدام useNavigate للتوجيه

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    try {
      await axios.post("/api/logout"); // استدعاء نقطة نهاية تسجيل الخروج
      logout(); // تحديث حالة المصادقة
      navigate("/login"); // إعادة توجيه إلى صفحة تسجيل الدخول بعد تسجيل الخروج
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      handleCloseUserMenu();
    }
  };

  const handleProfile = () => {
    navigate("/Profile"); // توجيه إلى صفحة /Profile
    handleCloseUserMenu();
  };

  const getUsername = () => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const { account } = JSON.parse(userData);
      return account;
    }
    return "";
  };

  const username = getUsername();

  return (
    <Container style={{ direction: direction, marginTop: "5px" }}>
      <AppBar
        position="static"
        sx={{
          color: "#FFD700",
          borderRadius: "15px",
          boxShadow: "0px 5px 15px rgba(0,0,0,0.9)",
        }}
      >
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src="/images/oddylogo.png"
              alt="شعار أوديسي"
              style={{ height: "70px", width: "70px" }}
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ color: "#FFD700" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography
                    component={Link}
                    to={page.path}
                    sx={{
                      textAlign: "center",
                      textDecoration: "none",
                      color: "#FFD700",
                      fontSize: "1rem",
                    }}
                  >
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src="/images/oddylogo.png"
              alt="شعار أوديسي"
              style={{ height: "70px", width: "70px" }}
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                component={Link}
                to={page.path}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "#FFD700",
                  display: "block",
                  textDecoration: "none",
                  fontSize: "1rem",
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
            <LanguageSwitcher />
            {isAuthenticated ? (
              <>
                <div
                  style={{
                    margin: "0px 10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      margin: "0px",
                      fontSize: "1rem",
                    }}
                  >
                    {username}
                  </Typography>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt="User Avatar" src="/images/avatar.png" />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ m: "60px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem
                        key={setting.key}
                        onClick={
                          setting.key === "logout"
                            ? handleLogout
                            : setting.key === "account"
                            ? handleProfile
                            : setting.key === "settings"
                            ? () => (window.location.href = "/settings")
                            : setting.key === "RedeemCoupon" // إضافة شرط الكوبون هنا
                            ? () => (window.location.href = "/RedeemCoupon") // توجيه إلى صفحة استبدال الكوبونات
                            : handleCloseUserMenu
                        }
                        sx={{ fontSize: "1rem" }}
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          {setting.label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0px 10px",
                  }}
                >
                  <Button
                    className="login-button"
                    component={Link}
                    to="/login"
                    sx={{ color: "#FFD700" }}
                  >
                    {t("تسجيل الدخول")}
                  </Button>
                  <Typography sx={{ margin: "0px 5px" }}></Typography>
                  <Button
                    className="login-button"
                    component={Link}
                    to="/register"
                    sx={{ color: "#FFD700" }}
                  >
                    {t("التسجيل")}
                  </Button>
                </div>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Container>
  );
}

export default ResponsiveAppBar;
