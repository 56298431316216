import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Avatar,
  Divider,
  Box,
  Button,
  Alert,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "../utils/axiosConfig";
import "../i18n";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState(""); // "success" or "error"
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonCooldown, setButtonCooldown] = useState(0);
  const [userData, setUserData] = useState(null); // استخدم حالة جديدة
  const [email, setEmail] = useState("");
  const [account, setAccount] = useState("");
  const [point, setPoint] = useState(null);
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(true); // حالة تحميل البيانات
  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    // الحصول على اللغة من localStorage
    const storedLanguage = localStorage.getItem("i18nextLng");

    // تعيين الاتجاه بناءً على اللغة
    if (storedLanguage === "ar") {
      setDirection("rtl");
    } else {
      setDirection("ltr");
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token"); // الحصول على التوكن من localStorage

      if (!token) {
        console.error("Token not found in localStorage");
        setLoading(false); // تأكد من إنهاء التحميل
        return;
      }

      try {
        const response = await axios.get("/api/checkAuth", {
          headers: {
            Authorization: `Bearer ${token}`, // إرسال التوكن في رأس الطلب
          },
        });

        // تحديث الحالة مع بيانات المستخدم
        setUserData(response.data);
        setAccount(response.data.account);
        setEmail(response.data.email);
        setPoint(response.data.point);
        setActive(response.data.active === 1);
      } catch (error) {
        console.error("Error fetching user data:", error);
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.message === "Token has expired"
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("userData");
          console.log("Token expired. Redirecting to login page...");
          window.location.href = "/login";
        }
      } finally {
        setLoading(false); // تأكد من إنهاء التحميل
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const savedCooldown = localStorage.getItem("buttonCooldown");
    const savedEndTime = localStorage.getItem("cooldownEndTime");

    if (savedCooldown && savedEndTime) {
      const currentTime = Math.floor(Date.now() / 1000);
      const endTime = parseInt(savedEndTime, 10);

      if (currentTime < endTime) {
        setButtonCooldown(endTime - currentTime);
        setIsButtonDisabled(true);
      } else {
        localStorage.removeItem("buttonCooldown");
        localStorage.removeItem("cooldownEndTime");
      }
    }
  }, []);

  useEffect(() => {
    let cooldownTimer;
    if (buttonCooldown > 0) {
      cooldownTimer = setInterval(() => {
        setButtonCooldown((prev) => {
          const newCooldown = prev - 1;
          if (newCooldown <= 0) {
            setIsButtonDisabled(false);
            localStorage.removeItem("buttonCooldown");
            localStorage.removeItem("cooldownEndTime");
            clearInterval(cooldownTimer);
            return 0;
          }
          return newCooldown;
        });
      }, 1000); // Decrease cooldown every second
    } else {
      clearInterval(cooldownTimer);
    }
    return () => clearInterval(cooldownTimer);
  }, [buttonCooldown]);

  useEffect(() => {
    if (isButtonDisabled && buttonCooldown > 0) {
      const endTime = Math.floor(Date.now() / 1000) + buttonCooldown;
      localStorage.setItem("cooldownEndTime", endTime.toString());
      localStorage.setItem("buttonCooldown", buttonCooldown.toString());
    }
  }, [isButtonDisabled, buttonCooldown]);

  const handleSendVerificationEmail = async () => {
    if (!userData || !userData.email) {
      setMessage(t("لا يمكن إرسال رسالة التحقق. بيانات المستخدم مفقودة"));
      setSeverity("error");
      return;
    }

    if (isButtonDisabled) {
      return; // Do nothing if the button is disabled
    }

    setIsButtonDisabled(true);
    setButtonCooldown(180); // 3 minutes = 180 seconds
    const language = localStorage.getItem("i18nextLng");
    try {
      const response = await axios.post("/api/resendVerificationEmail", {
        email: userData.email,
        language: language,
      });

      if (response.status === 201) {
        setMessage(t("تم إرسال رسالة التحقق بنجاح!"));
        setSeverity("success");
      } else {
        setMessage(t("حدث خطأ أثناء إرسال رسالة التحقق"));
        setSeverity("error");
      }
    } catch (error) {
      setMessage(
        `خطأ في إرسال رسالة التحقق: ${error.response?.data || error.message}`
      );
      setSeverity("error");
    }
  };

  return (
    <Container
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        minHeight: "66vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div dir={direction}>
        <Card sx={{ maxWidth: 400, width: "100%", borderRadius: "15px" }}>
          <CardContent
            sx={{
              direction: direction,
              backgroundColor: "#fff",
              borderRadius: "15px",
            }}
          >
            <Avatar
              alt="User Avatar"
              src="/static/images/avatar/2.jpg"
              sx={{
                width: 80,
                height: 80,
                marginBottom: "20px",
                marginX: "auto",
              }}
            />
            <Typography
              variant="h5"
              component="div"
              sx={{ marginBottom: "10px", textAlign: "center" }}
            >
              {loading ? "Loading..." : account}
            </Typography>
            <Divider sx={{ marginBottom: "10px" }} />
            <Typography variant="body1" sx={{ marginBottom: "10px" }}>
              <strong>{t("البريد الإلكتروني:")}</strong>{" "}
              {loading ? "Loading..." : email}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: "10px" }}>
              <strong>{t("النقاط")}</strong> {loading ? "Loading..." : point}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              {message && (
                <Alert severity={severity} sx={{ mb: 2 }}>
                  {message}
                </Alert>
              )}
              {active ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CheckCircleIcon sx={{ color: "green", m: 1 }} />
                  <Typography variant="body1">{t("الحساب مفعل")}</Typography>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CancelIcon sx={{ color: "red", m: 1 }} />
                    <Typography variant="body1">
                      {t("الحساب غير مفعل")}
                    </Typography>
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handleSendVerificationEmail}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled
                      ? `Wait ${buttonCooldown}s`
                      : t("إرسال رسالة التفعيل")}
                  </Button>
                </>
              )}
            </Box>
            {!loading && userData && userData.permission === 500 && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 2, width: "100%" }}
                onClick={() => navigate("/panel")}
              >
                {t("لوحة التحكم")}
              </Button>
            )}
          </CardContent>
        </Card>
      </div>
    </Container>
  );
}

export default Profile;
