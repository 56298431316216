import React from "react";

// components
import SideBar from "../components/SideBar";
import ServerInfo from "../components/ServerInfo";
import NewsList from "../components/NewsList";

// MUI
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Discord from "../components/Discord";
import Offer from "../components/Offer";
function Home() {
  return (
    <div
      dir="rtl"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Container style={{ flex: 1 }}>
        <Grid
          container
          spacing={1}
          style={{ height: "100%", margin: "10px 0" }}
        >
          {/* السايد بار */}
          <Grid item xs={12} sm={4}>
            <Paper
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
            >
              <SideBar />
            </Paper>
          </Grid>

          {/* القسمين */}
          <Grid item xs={12} sm={8}>
            <Grid
              container
              direction="column"
              spacing={2}
              style={{ height: "100%" }}
            >
              {/* القسم الأول */}
              <Grid item xs={12} style={{ flex: 2 }}>
                <ServerInfo />
              </Grid>

              {/* القسم الثاني */}
              <Grid item xs={12} style={{ flex: 1, overflow: "auto" }}>
                <NewsList />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container >
      <Grid container spacing={2} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Grid item xs={11} sm={5}>
          <Offer />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Discord />
        </Grid>
      </Grid>
    </Container>
    </div>
  );
}

export default Home;
