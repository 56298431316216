// PublicRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PublicRoute = () => {
  const { isAuthenticated } = useAuth();

  // إذا كان المستخدم مسجلاً دخوله، توجيهه إلى الصفحة الرئيسية
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  // إذا لم يكن المستخدم مسجلاً دخوله، عرض المحتوى العام
  return <Outlet />;
};

export default PublicRoute;
