import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";

const AccountDetailsDialog = ({ open, onClose, selectedAccount }) => {
  const [editableAccount, setEditableAccount] = useState({});

  useEffect(() => {
    setEditableAccount(selectedAccount || {});
  }, [selectedAccount]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle align="center">تفاصيل الحساب</DialogTitle>
        <DialogContent sx={{ backgroundColor: "#4B0082", color: "#ffffff" }}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {Object.entries(editableAccount).map(([key, value]) => (
              <Grid item xs={12} sm={6} key={key}>
                <Typography variant="body1" align="center">
                  <strong>{key}:</strong> {value !== null ? value : "غير متوفر"}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            إغلاق
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={Boolean(editableAccount.snackbarOpen)}
        autoHideDuration={6000}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          تم عرض تفاصيل الحساب.
        </Alert>
      </Snackbar>
    </>
  );
};

export default AccountDetailsDialog;
