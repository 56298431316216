import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const CharacterTable = ({ characters, onRowClick }) => {
  const formatPlayTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600); // حساب الساعات
    return `${hours}`; // عرض الساعات فقط
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead style={{ background: "#5B0052" }}>
          <TableRow>
            <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
              ID
            </TableCell>
            <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
              الاسم
            </TableCell>
            <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
              المستوى
            </TableCell>
            <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
              الحساب
            </TableCell>
            <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
              ID account
            </TableCell>
            <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
              الكتيبة
            </TableCell>
            <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
              الذهب
            </TableCell>
            <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
              نقاط الميدان
            </TableCell>
            <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
              نقاط التحدي
            </TableCell>
            <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
              وقت اللعب بالساعة
            </TableCell>
            <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
              حالة الاتصال
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {characters.map((character) => (
            <TableRow
              key={character.sid}
              onClick={() => onRowClick(character)}
              style={{ cursor: "pointer" }}
            >
              <TableCell style={{ textAlign: "center" }}>
                {character.sid}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {character.name}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {character.lv}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {character.account}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {character.account_id}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {character.GuildName || "لا يوجد"}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {character.gold}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {character.arena_point}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {character.huntaholic_point}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {formatPlayTime(character.play_time)}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {character.isOnline ? "متصل" : "غير متصل"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CharacterTable;
