import React from "react";
// import { useEffect, useState } from "react";
import "./styles.css";
import Top10 from "./Top10";
import DigitalClock from "./DigitalClock";
//MUI
import { Box, Typography } from "@mui/material";
//lib
// import axios from "../utils/axiosConfig";

export default function SideBar() {
  // const [serverStatus, setServerStatus] = useState("");
  // const [onlineCount, setOnlineCount] = useState(0);

  // useEffect(() => {
  //   axios
  //     .get("/api/onlineState")
  //     .then((response) => {
  //       setServerStatus(response.data.serverStatus);
  //       // setOnlineCount(response.data.onlineCount);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching server status:", error);
  //     });
  // }, []);

  return (
    <div dir="rtl">
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          p: 3,
          textAlign: "center",
          backgroundImage: `url("/images/gear.gif")`,
          backgroundPosition: "center",
          borderRadius: "15px",
          marginBottom: "20px",
          marginTop: "20px",
          width: "80%",
          color: "white",
          overflow: "hidden",
          "::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: 1,
            borderRadius: "inherit",
          },
          zIndex: 2,
        }}
      >
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ zIndex: 2 }}
        >
          <DigitalClock />
          {/* Server status
          <Button
            variant="contained"
            style={{
              backgroundColor: serverStatus === "Online" ? "green" : "red",
              cursor: "default",
              pointerEvents: "none",
              textShadow: "5px 5px 5px rgba(75, 0, 130, 1)",
            }}
          >
            {serverStatus === "Online" ? "Online" : "Offline"}
          </Button> */}
        </Typography>
        {/* <Typography
          variant="h6"
          component="div"
          gutterBottom
          sx={{
            zIndex: 2,
            textShadow: "5px 5px 5px rgba(75, 0, 130, 1)",
          }}
        >
          Online Playres: {onlineCount}
        </Typography> */}
      </Box>

      <div>
        <Top10 />
      </div>
    </div>
  );
}
