import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  Box,
  Container,
  Grid,
} from "@mui/material";
import "../i18n";
import { useTranslation } from "react-i18next";

function Download() {
  const { t } = useTranslation();

  // Define download links for each source
  const downloadLinks = [
    {
      name: "Mediafire",
      url: "https://www.mediafire.com/file/fshtbu94rghulc7/OdysseyClient.rar/file",
    },
    {
      name: "GOOGLE DRIVE",
      url: "https://drive.google.com/file/d/1bojbZ8fDyCK4K_Fa5mHiSuncD5O0WwK1/view?usp=sharing",
    },
    {
      name: "Mega",
      url: "https://mega.nz/file/PRNViZRB#mAH9OLcgOanqmV18twmkegKsh0k5-oUWnAuB2CjPFGE",
    },
  ];

  return (
    <div dir="rtl">
      <Container component="main" sx={{ padding: 1 }}>
        <Grid container>
          {/* Left Grid */}
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                bgcolor: "rgba(0, 0, 0, 0.4)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                padding: 2,
              }}
            >
              <Typography variant="h4" gutterBottom>
                {t("تحميل الكلينت")}
              </Typography>
              <Typography variant="body1" textAlign={"center"}>
                {t("قم بتحميل الكلينت من أحد الروابط وقم بافراغ الضغط")}
              </Typography>
              <div style={{ width: "90%" }}>
                {/* Client Cards */}
                {downloadLinks.map((link, index) => (
                  <Card
                    key={index}
                    sx={{
                      background: "rgba(75, 0, 30, 0.7)",
                      margin: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardContent style={{ textAlign: "center" }}>
                      <Typography variant="h6" component="div">
                        Oddysey Client
                      </Typography>
                      <Typography variant="h6" color="white">
                        {link.name}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          background: "#4B0082",
                          color: "#f7a809",
                          fontSize: "20px",
                        }}
                      >
                        {t("تحميل")}
                      </Button>
                    </CardActions>
                  </Card>
                ))}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Download;
