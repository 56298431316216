import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Alert,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig";
import "../i18n";
import { useTranslation } from "react-i18next";
const AccountSettings = () => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;
    const hasNumber = /[0-9]/;
    const hasNoSpaces = /^\S+$/;

    return (
      password.length >= minLength &&
      hasUpperCase.test(password) &&
      hasLowerCase.test(password) &&
      hasNumber.test(password) &&
      hasNoSpaces.test(password)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentPassword || !newPassword || !confirmPassword) {
      setError(t("يرجى ملء جميع الحقول"));
      return;
    }

    if (newPassword !== confirmPassword) {
      setError(t("كلمات المرور الجديدة لا تتطابق"));
      return;
    }

    if (!validatePassword(newPassword)) {
      setError(
        t(
          "يجب أن تتكون كلمة المرور الجديدة من 8 أحرف على الأقل، تشمل حرفًا كبيرًا، حرفًا صغيرًا، ورقم"
        )
      );
      return;
    }

    if (newPassword === currentPassword) {
      setError(
        t("كلمة المرور الجديدة لا يمكن أن تكون نفس كلمة المرور القديمة")
      );
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const token = localStorage.getItem("token");

      const response = await axios.patch(
        "/api/settings",
        { currentPassword, newPassword },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (response.data.message) {
        setSuccess(response.data.message);
        setTimeout(() => {
          navigate("/profile");
        }, 2000);
      } else {
        setError(t("حدث خطأ غير متوقع"));
      }
    } catch (err) {
      console.error("Error sending request:", err);
      setError(
        err.response?.data?.message || t("حدث خطأ أثناء تحديث كلمة المرور")
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs" sx={{ height: "56vh", mt: 8, mb: 4 }}>
      <Box
        sx={{
          textAlign: "center",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t("تغيير كلمة المرور")}
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        {loading && <CircularProgress />}
        <form onSubmit={handleSubmit}>
          <TextField
            label={t("كلمة المرور الحالية")}
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            required
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            sx={{ fontSize: "1.2rem" }}
            InputLabelProps={{
              style: { fontSize: "1.2rem", color: "#4B0082" },
            }}
          />
          <TextField
            label={t("كلمة المرور الجديدة")}
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            sx={{ fontSize: "1.2rem" }}
            InputLabelProps={{
              style: { fontSize: "1.2rem", color: "#4B0082" },
            }}
          />
          <TextField
            label={t("تأكيد كلمة المرور الجديدة")}
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{ fontSize: "1.2rem" }}
            InputLabelProps={{
              style: { fontSize: "1.2rem", color: "#4B0082" },
            }}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2, backgroundColor: "#4B0082", color: "#FFD700" }}
            type="submit"
            disabled={loading}
          >
            {loading ? t("جاري حفظ التغييرات...") : t("حفظ التغييرات")}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default AccountSettings;
