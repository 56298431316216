import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AccountDetailsDialog from "./AccountDetailsDialog";
import axios from "../utils/axiosConfig";

const AccountTable = ({ accounts, refreshAccounts }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [editableAccount, setEditableAccount] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  const handleRowClick = (account) => {
    setEditableAccount(account);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setEditableAccount(null);
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleActivate = async (accountId) => {
    setLoading(true);
    setSnackbarMessage("العملية قيد المعالجة...");
    setSnackbarSeverity("info");
    setSnackbarOpen(true);

    try {
      await axios.put("/api/characters/account/", {
        account_id: accountId,
        active: 1,
      });

      setSnackbarMessage("تم تفعيل الحساب بنجاح!");
      setSnackbarSeverity("success");
      refreshAccounts(); // إعادة تحميل الحسابات بعد النجاح
    } catch (error) {
      setSnackbarMessage(
        "خطأ في تفعيل الحساب: " +
          (error.response?.data.message || error.message)
      );
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const handleBlockToggle = async (accountId, currentBlockStatus) => {
    setLoading(true);
    setSnackbarMessage("العملية قيد المعالجة...");
    setSnackbarSeverity("info");
    setSnackbarOpen(true);

    const newBlockStatus = currentBlockStatus === 0 ? 1 : 0; // عكس حالة الحظر

    try {
      await axios.put("/api/characters/account/", {
        account_id: accountId,
        block: newBlockStatus,
      });

      setSnackbarMessage(
        newBlockStatus === 1 ? "تم حظر الحساب!" : "تم إزالة الحظر عن الحساب!"
      );
      setSnackbarSeverity("success");
      refreshAccounts(); // إعادة تحميل الحسابات بعد النجاح
    } catch (error) {
      setSnackbarMessage(
        "خطأ في تغيير حالة الحظر: " +
          (error.response?.data.message || error.message)
      );
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={{ background: "#5B0052" }}>
            <TableRow>
              <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                ID الحساب
              </TableCell>
              <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                اسم الحساب
              </TableCell>
              <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                البريد الإلكتروني
              </TableCell>
              <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                الصلاحيات
              </TableCell>
              <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                النقاط
              </TableCell>
              <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                الحالة
              </TableCell>
              <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                حالة الحظر
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((account) => (
              <TableRow
                key={account.account_id}
                onClick={() => handleRowClick(account)}
                style={{ cursor: "pointer" }}
              >
                <TableCell style={{ textAlign: "center" }}>
                  {account.account_id}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {account.account}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {account.email}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {account.permission}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {account.point}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {account.active ? (
                    "مفعل"
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleActivate(account.account_id);
                      }}
                    >
                      تفعيل
                    </Button>
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {account.block === 0 ? (
                    <Button
                      variant="contained"
                      color="error"
                      disabled={loading}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleBlockToggle(account.account_id, account.block);
                      }}
                    >
                      حظر
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      disabled={loading}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleBlockToggle(account.account_id, account.block);
                      }}
                    >
                      إزالة الحظر
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {editableAccount && (
        <AccountDetailsDialog
          open={openDialog}
          onClose={handleCloseDialog}
          selectedAccount={editableAccount}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MuiAlert
          elevation={6}
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default AccountTable;
