import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import axios from "../utils/axiosConfig";
import "../i18n";
import { useTranslation } from "react-i18next";

const animations = {
  fire: {
    "@keyframes fire": {
      "0%": { boxShadow: "0 0 5px rgba(255, 69, 0, 0.8)" },
      "50%": { boxShadow: "0 0 20px rgba(255, 69, 0, 0.8)" },
      "100%": { boxShadow: "0 0 5px rgba(255, 69, 0, 0.8)" },
    },
  },
  ice: {
    "@keyframes ice": {
      "0%": { boxShadow: "0 0 5px rgba(173, 216, 230, 0.8)" },
      "50%": { boxShadow: "0 0 15px rgba(173, 216, 230, 0.8)" },
      "100%": { boxShadow: "0 0 5px rgba(173, 216, 230, 0.8)" },
    },
  },
  stone: {
    "@keyframes stone": {
      "0%": { boxShadow: "0 0 5px rgba(139, 69, 19, 0.8)" },
      "50%": { boxShadow: "0 0 15px rgba(139, 69, 19, 0.8)" },
      "100%": { boxShadow: "0 0 5px rgba(139, 69, 19, 0.8)" },
    },
  },
};

const Top10 = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  
  useEffect(() => {
    axios
      .get("/api/rank")
      .then((response) => {
        // Sort data by level in descending order and limit to top 10 results
        const sortedData = response.data
          .sort((a, b) => b.Level - a.Level)
          .slice(0, 10);
        setData(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>جاري التحميل...</div>;
  if (error) return <div>خطأ: {error.message}</div>;

  return (
    <div dir="rtl">
      <TableContainer
        component={Paper}
        sx={{
          width: "100%", // Set width to 100%
          margin: "auto",
          marginTop: 4,
          background: "rgba(0, 0, 0, 0.7)",
          borderRadius: 2,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#FFD700",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "2px solid #FFD700",
                  padding: "4px 8px",
                }}
              >
               {t("الترتيب")}
              </TableCell>
              <TableCell
                sx={{
                  color: "#FFD700",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "2px solid #FFD700",
                  padding: "4px 8px",
                }}
              >
                {t("الاسم")}
              </TableCell>
              <TableCell
                sx={{
                  color: "#FFD700",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "2px solid #FFD700",
                  padding: "4px 8px",
                }}
              >
                {t("المستوى")}
              </TableCell>
              <TableCell
                sx={{
                  color: "#FFD700",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "2px solid #FFD700",
                  padding: "4px 8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("التخصص")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              let itemStyle = {
                bgcolor: "#4B0082",
                color: "white",
                fontSize: "14px",
                textAlign: "center",
                padding: "4px",
              };

              if (index === 0) {
                itemStyle = {
                  ...itemStyle,
                  ...animations.fire,
                  animation: "fire 1s infinite",
                  bgcolor: "#ff0808",
                };
              } else if (index === 1) {
                itemStyle = {
                  ...itemStyle,
                  ...animations.ice,
                  animation: "ice 1s infinite",
                  bgcolor: "#353436",
                };
              } else if (index === 2) {
                itemStyle = {
                  ...itemStyle,
                  ...animations.stone,
                  animation: "stone 1s infinite",
                  bgcolor: "#cd7f32",
                };
              }

              return (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                  }}
                >
                  <TableCell sx={itemStyle}>{index + 1}</TableCell>
                  <TableCell sx={itemStyle}>{row.CharacterName}</TableCell>
                  <TableCell sx={itemStyle}>{row.Level}</TableCell>
                  <TableCell
                    sx={{
                      ...itemStyle,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={`/images/jobs/${row.Job}.jpg`}
                      alt={`Class ${row.Job}`}
                      sx={{ width: 30, height: 30 }} // Adjusted size of Avatar
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Top10;
