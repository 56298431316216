import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Box,
  Alert,
  TextField,
  Avatar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../utils/axiosConfig";
import "../i18n";
import { useTranslation } from "react-i18next";

const Cart = ({ cart, open, onClose, onRemoveItem, onUpdateQuantity }) => {
  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [processing, setProcessing] = useState(false);

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  const handleCheckout = async () => {
    setProcessing(true);
    setAlertMessage(t("جاري معالجة طلب الشراء..."));
    setAlertSeverity("info");
    setAlertOpen(true);

    try {
      const token = localStorage.getItem("token");

      if (!token) {
        // setAlertMessage("التوكن مفقود");
        setAlertSeverity("error");
        setAlertOpen(true);
        setProcessing(false);
        return;
      }

      const cartData = cart.map((item) => ({
        item_code: item.item_code,
        quantity: item.quantity,
      }));

      await axios.post(
        "/api/checkout",
        { cart: cartData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAlertMessage(t("تمت العملية بنجاح"));
      setAlertSeverity("success");
      setAlertOpen(true);

      setTimeout(() => {
        setAlertOpen(false);
        localStorage.removeItem("cart");
        onClose();
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error(t("خطأ في عملية الشراء:"), error);

      let errorMessage = t("حدث خطأ في عملية الشراء");

      if (error.response && error.response.data) {
        const { message } = error.response.data;

        if (message) {
          if (message.includes(t("رصيد نقاط غير كاف"))) {
            errorMessage = t("لا تملك رصيد نقاط كافٍ لإتمام الشراء.");
          } else if (message.includes(t("خطأ في الخادم"))) {
            errorMessage = t("حدث خطأ في الخادم، يرجى المحاولة لاحقًا.");
          } else {
            errorMessage = message;
          }
        } else {
          errorMessage = t("حدث خطأ غير محدد، يرجى المحاولة لاحقًا.");
        }
      }

      setAlertMessage(errorMessage);
      setAlertSeverity("error");
      setAlertOpen(true);
    } finally {
      setProcessing(false);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    if (newQuantity >= 1) {
      onUpdateQuantity(itemId, newQuantity);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      style={{ textAlign: "center" }}
    >
      <DialogTitle variant="h3">{t("سلة التسوق")}</DialogTitle>
      <DialogContent>
        {alertOpen && (
          <Alert
            severity={alertSeverity}
            onClose={handleAlertClose}
            sx={{ mb: 2 }}
          >
            {alertMessage}
          </Alert>
        )}
        {cart.length === 0 ? (
          <Typography>{t("سلة التسوق فارغة")}</Typography>
        ) : (
          <>
            <div dir="rtl">
              <Box sx={{ display: "grid", gap: 2 }}>
                {cart.map((item) => (
                  <Card
                    key={item.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexGrow: 1,
                      }}
                    >
                      {/* صورة المنتج */}
                      <Avatar
                        src={`/images/shop/${item.item_icon}`} // عرض الصورة هنا
                        alt={item.item_name}
                        sx={{ width: 40, height: 40, marginRight: 2 }}
                      />
                      <Box>
                        <Typography variant="h6">{t(item.item_name)}</Typography>
                        <Typography variant="subtitle1">{`${t("السعر")}(${item.price}) ${t("نقطة")}`}</Typography>
                      </Box>
                      <div></div>
                      <TextField
                        type="number"
                        label={t("الكمية")}
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(
                            item.id,
                            parseInt(e.target.value)
                          )
                        }
                        inputProps={{ min: 1 }}
                        sx={{ width: 80, marginLeft: 2 }}
                      />
                    </CardContent>
                    <CardActions>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => onRemoveItem(item.id)}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </CardActions>
                  </Card>
                ))}
              </Box>
              <Box mt={2} display="flex" justifyContent="center">
                <Typography variant="h5" margin={1}>
                  {t("المجموع")}
                </Typography>
                <Typography variant="h5" margin={1}>
                  {totalPrice} {t("نقاط")}
                </Typography>
              </Box>
            </div>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleCheckout}
              disabled={processing}
            >
              {processing ? t("جاري معالجة الطلب...") : t("إتمام الشراء")}
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Cart;
