import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "../utils/axiosConfig";

const excludedAccountFields = [
  "account_id",
  "account",
  "creation_date",
  "update_date",
  "ip_address",
];

const excludedCharacterFields = [
  "sid",
  "account",
  "account_id",
  "GuildName",
  "isOnline",
];

const CharacterDetailsDialog = ({
  open,
  onClose,
  selectedCharacter,
  accountData,
  onUpdateSuccess,
}) => {
  const [isEditingCharacter, setIsEditingCharacter] = useState(false);
  const [isEditingAccount, setIsEditingAccount] = useState(false);
  const [editableCharacter, setEditableCharacter] = useState(selectedCharacter);
  const [editableAccount, setEditableAccount] = useState(accountData);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [message, setMessage] = useState(""); // New state for message input

  useEffect(() => {
    setEditableCharacter(selectedCharacter);
    setEditableAccount(accountData);
  }, [selectedCharacter, accountData]);

  const handleConfirmCharacter = async () => {
    const modifiedCharacter = Object.entries(editableCharacter).reduce(
      (acc, [key, value]) => {
        if (value !== selectedCharacter[key]) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    if (selectedCharacter.sid) {
      modifiedCharacter.sid = selectedCharacter.sid;
    }

    if (Object.keys(modifiedCharacter).length === 0) {
      showSnackbar("لا توجد حقول للتحديث.", "warning");
      return;
    }

    try {
      const response = await axios.put(
        "/api/characters/character",
        modifiedCharacter
      );
      showSnackbar(
        "تم تحديث بيانات الشخصية بنجاح: " + response.data.message,
        "success"
      );
      setIsEditingCharacter(false);
      setTimeout(() => {
        onUpdateSuccess();
        onClose();
      }, 2000);
    } catch (error) {
      showSnackbar(
        "خطأ في تحديث بيانات الشخصية: " +
          (error.response?.data.message || error.message),
        "error"
      );
    }
  };

  const handleCancelCharacter = () => {
    setEditableCharacter(selectedCharacter);
    setIsEditingCharacter(false);
  };

  const handleConfirmAccount = async () => {
    const modifiedAccount = Object.entries(editableAccount).reduce(
      (acc, [key, value]) => {
        if (value !== accountData[key]) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    const accountId = editableAccount.account_id;
    if (Object.keys(modifiedAccount).length === 0) {
      showSnackbar("لا توجد حقول للتحديث.", "warning");
      return;
    }

    try {
      const response = await axios.put("/api/characters/account/", {
        account_id: accountId,
        ...modifiedAccount,
      });

      showSnackbar(
        "تم تحديث بيانات الحساب بنجاح: " + response.data.message,
        "success"
      );
      setIsEditingAccount(false);
      setTimeout(() => {
        onUpdateSuccess();
        onClose();
      }, 2000);
    } catch (error) {
      showSnackbar(
        "خطأ في تحديث بيانات الحساب: " +
          (error.response?.data.message || error.message),
        "error"
      );
    }
  };

  const handleCancelAccount = () => {
    setEditableAccount(accountData);
    setIsEditingAccount(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
    setTimeout(() => {
      setSnackbar((prev) => ({ ...prev, open: false }));
    }, 2000);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  const handleSendMessage = async () => {
    // تأكد من أن الرسالة تحتوي على قيمة
    if (!message) {
      showSnackbar("يرجى إدخال مدة الكتم.", "warning");
      return;
    }

    const chatBlockTime = parseInt(message, 10); // تحويل القيمة إلى عدد صحيح

    if (isNaN(chatBlockTime) || chatBlockTime <= 0) {
      showSnackbar("يرجى إدخال مدة صحيحة.", "warning");
      return;
    }

    const dataToSend = {
      sid: selectedCharacter.sid,
      chat_block_time: chatBlockTime,
    };

    try {
      const response = await axios.put("/api/characters/character", dataToSend);
      showSnackbar("تم إرسال الرسالة: " + response.data.message, "success");
      setMessage(""); // مسح حقل الإدخال
    } catch (error) {
      showSnackbar(
        "خطأ في إرسال الرسالة: " +
          (error.response?.data.message || error.message),
        "error"
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>تفاصيل الشخصية</DialogTitle>
      <DialogContent>
        {selectedCharacter && accountData && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div style={{display:"flex", width:"50%",justifyContent:"center",}}>
                <TextField
                  label="كتم الشخصية"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  fullWidth
                  variant="outlined"
                />
                <Button onClick={handleSendMessage} variant="contained">
                  إرسال
                </Button>
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                معلومات الشخصية
              </Typography>
              {isEditingCharacter ? (
                <>
                  <Table>
                    <TableBody>
                      {Object.entries(editableCharacter).map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCell>
                            <strong>{key}:</strong>
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={value !== null ? value : ""}
                              onChange={(e) =>
                                setEditableCharacter({
                                  ...editableCharacter,
                                  [key]: e.target.value,
                                })
                              }
                              disabled={excludedCharacterFields.includes(key)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Button onClick={handleConfirmCharacter}>تأكيد</Button>
                  <Button onClick={handleCancelCharacter}>إلغاء</Button>
                </>
              ) : (
                <Table>
                  <TableBody>
                    {Object.entries(selectedCharacter).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>
                          <strong>{key}:</strong>
                        </TableCell>
                        <TableCell>
                          {value !== null && value !== undefined
                            ? value.toString()
                            : "لا يوجد"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                بيانات الحساب
              </Typography>
              {isEditingAccount ? (
                <>
                  <Table>
                    <TableBody>
                      {Object.entries(editableAccount).map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCell>
                            <strong>{key}:</strong>
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={value !== null ? value : ""}
                              onChange={(e) =>
                                setEditableAccount({
                                  ...editableAccount,
                                  [key]: e.target.value,
                                })
                              }
                              disabled={excludedAccountFields.includes(key)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Button onClick={handleConfirmAccount}>تأكيد</Button>
                  <Button onClick={handleCancelAccount}>إلغاء</Button>
                </>
              ) : (
                <Table>
                  <TableBody>
                    {Object.entries(accountData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>
                          <strong>{key}:</strong>
                        </TableCell>
                        <TableCell>
                          {value !== null && value !== undefined
                            ? value.toString()
                            : "لا يوجد"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          إغلاق
        </Button>
      </DialogActions>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default CharacterDetailsDialog;
