import React, { useEffect, useState } from "react";
import "../i18n";
import { useTranslation } from "react-i18next";

// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function ServerInfo() {
  const { t } = useTranslation();
  const [videoSrc, setVideoSrc] = useState("");

  useEffect(() => {
    // احصل على اللغة الحالية من localStorage
    const language = localStorage.getItem("i18nextLng") || "en";

    // تعيين رابط الفيديو بناءً على اللغة
    if (language === "ar") {
      setVideoSrc("https://www.youtube.com/embed/eRCbWknIeHY");
    } else {
      setVideoSrc("https://www.youtube.com/embed/PxaHftfBliA");
    }
  }, []);

  return (
    <div>
      <Card
        style={{
          backgroundColor: "rgba(75, 0, 130, 0.5)",
          borderRadius: "20px",
        }}
      >
        <CardContent sx={{ textAlign: "center" }}>
          <Typography gutterBottom variant="h3" component="div">
            {t("معلومات السيرفر")}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {t(
              "سيرفر Odyssey يقدم لك تجربة Rappelz استثنائية بإصدار 9.5.2 مع إضافات حصرية ومشوقة تعزز من تجربة القتال بين الوحوش واللاعبين. استعد للانغماس في عالم مليء بالتحديات والمغامرات، حيث تنتظرك معارك حماسية وجوائز قيمة تجعل كل لحظة مليئة بالإثارة. انطلق الآن واستمتع بتجربة لن تجد مثلها في أي مكان آخر!"
            )}
          </Typography>
          <Typography variant="body2" sx={{ color: "white" }}>
            {t("سهولة السيرفر")}: {t("متوسط")}
          </Typography>
          <Typography variant="body2" sx={{ color: "white" }}>
            {t("السقوط")}: x5
          </Typography>
          <Typography variant="body2" sx={{ color: "white" }}>
            {t("الخبرة")}: x6
          </Typography>
        </CardContent>
        <CardMedia
          component="iframe"
          height="315"
          width="100%"
          src={videoSrc}
          title="Server Info Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Card>
    </div>
  );
}
