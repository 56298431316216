// PrivateRoute.js
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth();

  // إذا لم يكن المستخدم مسجلاً دخوله، توجيهه إلى صفحة تسجيل الدخول
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // إذا كان المستخدم مسجلاً دخوله، عرض المحتوى المحمي
  return <Outlet />;
};

export default PrivateRoute;
