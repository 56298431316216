import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "../utils/axiosConfig";

const Shop = () => {
  const [items, setItems] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // حالة المودال
  const [openDialog, setOpenDialog] = useState(false);
  const [itemCode, setItemCode] = useState("");
  const [itemCount, setItemCount] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  // حالة التعديل
  const [editItemId, setEditItemId] = useState(null);
  // const [editItemCode, setEditItemCode] = useState("");
  const [editItemCount, setEditItemCount] = useState(0);
  const [editItemPrice, setEditItemPrice] = useState(0);
  const [editItemCategorie, setEditItemCategorie] = useState("");

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get("/api/shop");
        setItems(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };

    fetchItems();
  }, []);

  const handleDeleteItem = async (item_code) => {
    try {
      await axios.delete("/api/panelShop", { data: { item_code } });
      setItems((prevItems) =>
        prevItems.filter((item) => item.item_code !== item_code)
      );
      setSnackbarMessage("تم حذف العنصر بنجاح!");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
    } catch (error) {
      setSnackbarMessage("فشل حذف العنصر.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };

  const handleEditItem = (item) => {
    setEditItemId(item.item_code);
    // setEditItemCode(item.item_code);
    setEditItemCount(item.item_count);
    setEditItemPrice(item.item_point);
    setEditItemCategorie(item.item_categorie);
  };

  const handleCancelEdit = () => {
    setEditItemId(null);
    // setEditItemCode("");
    setEditItemCount(0);
    setEditItemPrice(0);
    setEditItemCategorie("");
  };

  const handleSaveEdit = async () => {
    setIsProcessing(true);
    try {
      await axios.put("/api/panelShop", {
        item_code: editItemId,
        item_count: editItemCount,
        item_price: editItemPrice,
        item_categorie: editItemCategorie,
      });
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.item_code === editItemId
            ? {
                ...item,
                item_count: editItemCount,
                item_point: editItemPrice,
                item_categorie: editItemCategorie,
              }
            : item
        )
      );
      setSnackbarMessage("تم تحديث العنصر بنجاح.");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      handleCancelEdit();
    } catch (error) {
      setSnackbarMessage("فشل تحديث العنصر.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAddItem = async () => {
    setIsProcessing(true); // تحديد أن الطلب قيد المعالجة
    try {
      const response = await axios.post("/api/panelShop", {
        item_code: itemCode,
        item_count: itemCount,
        item_price: itemPrice,
      });
      console.log("Server response:", response.data);
      setItems((prevItems) => [
        ...prevItems,
        {
          item_code: itemCode,
          item_count: itemCount,
          item_point: itemPrice,
          item_name: "New Item", // تحديث مع التفاصيل الفعلية إذا كانت متاحة
        },
      ]);
      setSnackbarMessage("تم إضافة رمز العنصر بنجاح.");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      handleCloseDialog();
    } catch (error) {
      console.error("فشل إضافة رمز العنصر:", error);
      setSnackbarMessage("فشل إضافة رمز العنصر.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    } finally {
      setIsProcessing(false); // إيقاف حالة المعالجة في النهاية
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setItemCode("");
    setItemCount(0);
    setItemPrice(0);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Container maxWidth="lg" style={{ direction: "rtl" }}>
      <Box
        sx={{ p: 3, bgcolor: "#ffffff", borderRadius: 1, boxShadow: 1, mb: 2 }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">إدارة المتجر</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            إضافة عنصر جديد
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: "#5B0052" }}>
              <TableRow>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الصورة
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الاسم
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الوصف
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  السعر بالنقاط
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  رمز العنصر
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  العدد المتوفر
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  فئة العنصر
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الإجراءات
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.item_code}>
                  <TableCell style={{ textAlign: "center" }}>
                    <img
                      src={`/images/shop/${item.item_icon}`}
                      alt={item.item_name}
                      style={{ width: 40, height: 40, objectFit: "cover" }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.item_name}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.item_desc}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editItemId === item.item_code ? (
                      <TextField
                        type="number"
                        value={editItemPrice}
                        onChange={(e) =>
                          setEditItemPrice(Number(e.target.value))
                        }
                      />
                    ) : (
                      item.item_point
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.item_code} {/* Fixed item_code */}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editItemId === item.item_code ? (
                      <TextField
                        type="number"
                        value={editItemCount}
                        onChange={(e) =>
                          setEditItemCount(Number(e.target.value))
                        }
                      />
                    ) : (
                      item.item_count
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editItemId === item.item_code ? (
                      <TextField
                        type="text"
                        value={editItemCategorie}
                        onChange={(e) => setEditItemCategorie(e.target.value)}
                      />
                    ) : (
                      item.item_categorie
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editItemId === item.item_code ? (
                      <>
                        <Button
                          color="success" // Updated color
                          variant="contained" // Ensures the button is solid
                          onClick={handleSaveEdit}
                          disabled={isProcessing}
                        >
                          حفظ
                        </Button>
                        <Button
                          color="default" // Updated color
                          variant="outlined" // Outlined button style
                          onClick={handleCancelEdit}
                        >
                          إلغاء
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          color="info" // Updated color
                          variant="contained" // Ensures the button is solid
                          onClick={() => handleEditItem(item)}
                        >
                          تعديل
                        </Button>
                        <Button
                          color="error" // Retained color
                          variant="contained" // Ensures the button is solid
                          onClick={() => handleDeleteItem(item.item_code)}
                        >
                          حذف
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>إضافة عنصر جديد</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="رمز العنصر"
            type="text"
            fullWidth
            value={itemCode}
            onChange={(e) => setItemCode(e.target.value)}
          />
          <TextField
            margin="dense"
            label="العدد"
            type="number"
            fullWidth
            value={itemCount}
            onChange={(e) => setItemCount(Number(e.target.value))}
          />
          <TextField
            margin="dense"
            label="السعر بالنقاط"
            type="number"
            fullWidth
            value={itemPrice}
            onChange={(e) => setItemPrice(Number(e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            إلغاء
          </Button>
          <Button
            onClick={handleAddItem}
            color="primary"
            disabled={isProcessing}
          >
            {isProcessing ? "جاري المعالجة..." : "إضافة"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Shop;
