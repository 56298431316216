import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const GameGuide = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        minHeight: "100vh", // لضمان أن الصفحة تملأ الشاشة بالكامل
        padding: 2, // لإضافة مساحة حول المحتوى
      }}
    >
      <Typography variant="h4" gutterBottom align="center">
        دليل اللعبة
      </Typography>

      <Accordion sx={{ width: "80%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography align="center" sx={{ width: "100%" }}>
            من أين أبدأ؟
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography align="center">
            شرح الخطوات الأولى للعبة. يمكن إدراج فيديو أو صور هنا.
            <img
              src="/images/GameGuide/1.png" // تغيير المسار حسب موقع الصورة
              alt="Game Start Guide"
              style={{ width: "100%", marginTop: "20px" }}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ width: "80%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography align="center" sx={{ width: "100%" }}>
            كيف أرفع لفلي؟
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography align="center">
            شرح طريقة رفع المستوى مع الصور.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* أضف المزيد من الأسئلة */}
    </Box>
  );
};

export default GameGuide;
