import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
  Box,
} from "@mui/material";
import axios from "../utils/axiosConfig"; // تأكد من أن axiosConfig مضبوط بشكل صحيح
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha"; // استيراد مكتبة reCAPTCHA

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null); // إضافة حالة للقيمة الخاصة بـ reCAPTCHA
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !password || !captchaValue) {
      // تأكد من وجود جميع الحقول المطلوبة
      setError(t("يرجى ملء جميع الحقول"));
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post("/api/login", {
        email,
        password,
        recaptchaValue: captchaValue, // تأكد من إرسال قيمة reCAPTCHA
      });
      const { token, userData } = response.data;
      login(token, userData);
      setSuccess("تم تسجيل الدخول بنجاح!");
    } catch (err) {
      console.error(
        "Error submitting login: ",
        err.response?.data || err.message
      );
      setError(t("البريد الإلكتروني أو كلمة المرور غير صحيحة"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        navigate("/"); // تغيير المسار بعد النجاح
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [success, navigate]);

  return (
    <Container maxWidth="xs" sx={{ height: "56vh", mt: 8, mb: 4 }}>
      <Box
        sx={{
          textAlign: "center",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t("تسجيل الدخول")}
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        {loading && <CircularProgress />}
        <form onSubmit={handleSubmit}>
          <TextField
            label={t("البريد الإلكتروني")}
            variant="outlined"
            type="email"
            fullWidth
            margin="normal"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ fontSize: "1.2rem" }}
            InputLabelProps={{
              style: { fontSize: "1.2rem", color: "#4B0082" },
            }}
          />
          <TextField
            label={t("كلمة المرور")}
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ fontSize: "1.2rem" }}
            InputLabelProps={{
              style: { fontSize: "1.2rem", color: "#4B0082" },
            }}
          />
          <ReCAPTCHA
            //المحلي
            // sitekey="6Ld_S2AqAAAAAHb4m7ptd9pLe-75ZFBIYJb35edC"
            //السيرفر
            // sitekey="6LdqSGAqAAAAAEAR7tFioMkXGTgSGamv6BB7yLEm"
            //الاستضافة
             sitekey="6Lch0X8qAAAAAPbeiCZaxXUeyEIYpcGDTm3MZcKr"
            onChange={setCaptchaValue} // تحديث الحالة عند التحقق
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            type="submit"
            disabled={loading}
          >
            {loading ? t("جاري تسجيل الدخول..") : t("تسجيل الدخول")}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
