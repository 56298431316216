import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Snackbar,
  Alert,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "../utils/axiosConfig";
import CharacterTable from "./CharacterTable";
import CharacterDetailsDialog from "./CharacterDetailsDialog";

const sortOptions = {
  lv: "المستوى",
  huntaholic_point: "نقاط التحدي",
  immoral_point: "نقاط القتل",
  gold: "الذهب",
  arena_point: "نقاط الميدان",
  play_time: "وقت اللعب",
  account: "الحساب",
  sid: "معرف الشخصية",
};

const validSortFields = Object.keys(sortOptions);

const Characters = () => {
  const [characters, setCharacters] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState("lv");
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchCharacters = async (page, sortBy, searchTerm = "") => {
    try {
      const response = await axios.get(
        `/api/characters?page=${page}&limit=10&sortBy=${sortBy}&searchTerm=${searchTerm}`
      );
      setCharacters(response.data.characters);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setSnackbarMessage("فشل تحميل الشخصيات.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };

  const fetchAccountData = async (accountId) => {
    try {
      const response = await axios.get(`/api/characters/account/${accountId}`);
      setAccountData(response.data);
    } catch (error) {
      setSnackbarMessage("فشل تحميل بيانات الحساب.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };

  useEffect(() => {
    fetchCharacters(page, sortBy, searchTerm);
  }, [page, sortBy, searchTerm]);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.value);
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    setPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleRowClick = (character) => {
    setSelectedCharacter(character);
    fetchAccountData(character.account_id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCharacter(null);
    setAccountData(null);
  };

  const handleUpdateSuccess = () => {
    fetchCharacters(page, sortBy, searchTerm);
  };

  return (
    <Container maxWidth="lg" style={{ direction: "rtl" }}>
      <Box
        sx={{ p: 3, bgcolor: "#ffffff", borderRadius: 1, boxShadow: 1, mb: 2 }}
      >
        <Box
          display="flex"
          mb={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4" gutterBottom>
            الشخصيات
          </Typography>
          <TextField
            label="ابحث عن الشخصية"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: "250px" }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1" sx={{ mr: 1 }}>
              فرز حسب:
            </Typography>
            <Select
              value={sortBy}
              onChange={handleSortChange}
              variant="outlined"
            >
              {validSortFields.map((field) => (
                <MenuItem key={field} value={field}>
                  {sortOptions[field]}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Box>
        <CharacterTable characters={characters} onRowClick={handleRowClick} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrevPage}
            disabled={page === 1}
          >
            {" "}
            السابق{" "}
          </Button>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              type="number"
              value={page}
              onChange={handlePageChange}
              inputProps={{ min: 1, max: totalPages }}
              sx={{ mx: 2, width: "100px", textAlign: "center" }}
              variant="outlined"
            />
            <Typography variant="body1">من {totalPages} صفحة</Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNextPage}
            disabled={page === totalPages}
          >
            {" "}
            التالي{" "}
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CharacterDetailsDialog
        open={openModal}
        onClose={handleCloseModal}
        selectedCharacter={selectedCharacter}
        accountData={accountData}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </Container>
  );
};

export default Characters;
