// src/pages/NotFound.js
import React from "react";
import "../i18n";
import { useTranslation } from "react-i18next";
function NotFound() {
  const { t } = useTranslation();
  return (
    <div style={{ height:"60vh",textAlign: "center", padding: "50px", backgroundColor: "rgba(0, 0, 0, 0.7)" }}>
      <h1>{t("404 - الصفحة غير موجودة")}</h1>
      <p>{t("عذرًا، الصفحة التي تحاول الوصول إليها غير موجودة.")}</p>
      <a href="/" style={{ color: "#FFD700" }}>
        {t("العودة إلى الصفحة الرئيسية")}
      </a>
    </div>
  );
}

export default NotFound;
