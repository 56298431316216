import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import axios from "../utils/axiosConfig";

const Ranking = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get("/api/rank")
      .then((response) => {
        // فرز البيانات حسب المستوى من الأعلى إلى الأسفل
        const sortedData = response.data.sort((a, b) => b.Level - a.Level);
        setData(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div dir="rtl">
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "80%",
          margin: "auto",
          marginTop: 4,
          background: "rgba(0, 0, 0, 0.7)",
          borderRadius: 2,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#FFD700",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "2px solid #FFD700",
                }}
              >
                Rank
              </TableCell>
              <TableCell
                sx={{
                  color: "#FFD700",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "2px solid #FFD700",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  color: "#FFD700",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "2px solid #FFD700",
                }}
              >
                Guild
              </TableCell>
              <TableCell
                sx={{
                  color: "#FFD700",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "2px solid #FFD700",
                }}
              >
                Class
              </TableCell>
              <TableCell
                sx={{
                  color: "#FFD700",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "2px solid #FFD700",
                }}
              >
                Level
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(even)": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                }}
              >
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "18px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "18px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {row.CharacterName}
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "18px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {row.GuildName || "----"}{" "}
                  {/* عرض اسم الكتيبة أو "No Guild" إذا لم يكن هناك كتيبة */}
                </TableCell>
                <TableCell
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    fontSize: "18px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  <Avatar
                    src={`/images/jobs/${row.Job}.jpg`}
                    sx={{ width: 40, height: 40 }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "18px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {row.Level}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Ranking;
