import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import "../i18n";
import { useTranslation } from "react-i18next";

const TermsModal = ({ open, onClose }) => {
  const { t } = useTranslation();

  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    // الحصول على اللغة من localStorage
    const storedLanguage = localStorage.getItem("i18nextLng");

    // تعيين الاتجاه بناءً على اللغة
    if (storedLanguage === "ar") {
      setDirection("rtl");
    } else {
      setDirection("ltr");
    }
  }, []);
  return (
    <div dir="rtl">
      <Dialog
        open={open}
        sx={{ direction:direction }}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle textAlign="center">{t("اتفاقية المستخدم وقوانين السيرفر")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            <br />
            <p>
              <pre>{t("server_rules")}</pre>
            </p>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <br />
            <p>
              <pre>{t("server_guidelines")}</pre>
            </p>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t("إغلاق")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TermsModal;
