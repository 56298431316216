import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const RedeemedCouponsTable = ({ logs }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>الرقم التعريفي</TableCell>
            <TableCell>معرف المستخدم</TableCell>
            <TableCell>البريد الإلكتروني للاعب</TableCell>{" "}
            {/* تغيير مكان البريد الإلكتروني */}
            <TableCell>معرف الكوبون</TableCell>
            <TableCell>تاريخ الاسترداد</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((log) => (
            <TableRow key={log.id}>
              <TableCell>{log.id}</TableCell>
              <TableCell>{log.user_id}</TableCell>
              <TableCell>{log.player_email || "غير متوفر"}</TableCell>{" "}
              {/* عرض البريد الإلكتروني أو رسالة بديلة إذا كان فارغًا */}
              <TableCell>{log.coupon_id}</TableCell>
              <TableCell>
                {new Date(log.redeemed_at).toLocaleString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RedeemedCouponsTable;
