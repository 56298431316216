import React, { useState } from "react";
import axios from "../utils/axiosConfig";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Typography,
  Paper,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import "../i18n";
import { useTranslation } from "react-i18next";

const RedeemCoupon = () => {
    const { t } = useTranslation();
  const [code, setCode] = useState(""); // حالة لكود الكوبون
  const [snackbarOpen, setSnackbarOpen] = useState(false); // حالة لفتح Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // رسالة Snackbar
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // نوع الرسالة
  const [loading, setLoading] = useState(false); // حالة لعرض التحميل

  // دالة لاستبدال الكوبون
  const handleRedeemCoupon = async () => {
    const token = localStorage.getItem("token"); // جلب التوكن من localStorage

    if (!token) {
      setSnackbarMessage(t("يرجى تسجيل الدخول أولاً"));
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true); // بدء التحميل
    try {
      // إجراء طلب POST لاستبدال الكوبون مع التوكن
      const response = await axios.post(
        "/api/coupons/redeem-coupon",
        { code }, // إرسال كود الكوبون فقط
        {
          headers: {
            Authorization: `Bearer ${token}`, // إرسال التوكن في الهيدر
          },
        }
      );
      setSnackbarMessage(response.data.message); // تعيين رسالة النجاح
      setSnackbarSeverity("success"); // تعيين نوع الرسالة
    } catch (error) {
      // التعامل مع الأخطاء
      setSnackbarMessage(error.response?.data.message || t("خطأ غير معروف")); // تعيين رسالة الخطأ
      setSnackbarSeverity("error"); // تعيين نوع الرسالة
    } finally {
      setSnackbarOpen(true); // فتح Snackbar
      setLoading(false); // إنهاء التحميل
    }
  };

  // دالة لإغلاق Snackbar
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="sm" style={{ padding: "20px", height: "90%" }}>
      <Paper elevation={3} style={{ padding: "20px", borderRadius: "8px" }}>
        <Typography variant="h4" gutterBottom align="center" color="primary">
          {t("استبدال الكوبون")}
        </Typography>
        <Card variant="outlined">
          <CardContent>
            <TextField
              label={t("كود الكوبون")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={code}
              onChange={(e) => setCode(e.target.value)} // تحديث كود الكوبون
            />
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRedeemCoupon} // استدعاء الدالة عند الضغط على الزر
              fullWidth
              disabled={loading} // تعطيل الزر أثناء التحميل
            >
              {loading ? t("جاري الاستبدال...") : t("استبدال الكوبون")}
            </Button>
          </CardActions>
        </Card>

        {/* Snackbar لعرض الرسائل */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
};

export default RedeemCoupon;
