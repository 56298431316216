// components/BetaBanner.js
import React from "react";
import { Box, Typography } from "@mui/material";
import "../i18n";
import { useTranslation } from "react-i18next";

function BetaBanner() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "red", // تحديد خلفية باللون الأحمر
        color: "white", // تحديد لون النص بالأبيض
        textAlign: "center",
        padding: "8px",
      }}
    >
      <Typography variant="body2" color="white">
        {t(
          "هذا الموقع في مرحلة بيتا. إذا واجهت مشاكل في الموقع، يرجى الانتظار قليلاً وسوف يعود للعمل تلقائيا لأنه تحت الخادم التجريبي."
        )}
      </Typography>
    </Box>
  );
}

export default BetaBanner;
