import axios from "axios";

const instance = axios.create({
  // baseURL: "https://server-oddysey.onrender.com/",
  // baseURL: "http://localhost:5500/",
 baseURL: "https://serverside.rappelz-odyssey.com/",
  withCredentials: true, // لضمان إرسال واستقبال ملفات تعريف الارتباط
});

export default instance;
