import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

// دالة لتصفير الأرقام
function zeroPadding(num, digit) {
  var zero = "";
  for (var i = 0; i < digit; i++) {
    zero += "0";
  }
  return (zero + num).slice(-digit);
}

const DigitalClock = () => {
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const week = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

    const updateTime = () => {
      const cd = new Date();
      setTime(
        zeroPadding(cd.getHours(), 2) +
          ":" +
          zeroPadding(cd.getMinutes(), 2) +
          ":" +
          zeroPadding(cd.getSeconds(), 2)
      );
      setDate(
        zeroPadding(cd.getFullYear(), 4) +
          "-" +
          zeroPadding(cd.getMonth() + 1, 2) +
          "-" +
          zeroPadding(cd.getDate(), 2) +
          " " +
          week[cd.getDay()]
      );
    };

    // التحديث كل ثانية
    const timerID = setInterval(updateTime, 1000);
    updateTime(); // تحديث فوري عند التحميل

    // تنظيف المؤقت عند تدمير المكون
    return () => clearInterval(timerID);
  }, []);

  return (
    <Box
      sx={{
        fontFamily: "'Share Tech Mono', monospace",
        color: "#daf6ff",
        textAlign: "center",
        textShadow:
          "0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0)",
        width: "200px", 
        height: "80px",
        margin: "auto",  
        borderRadius: "15px", 
        padding: "5px", 
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      
      }}
    >
      <Typography
        variant="h3"
        component="p"
        sx={{
          letterSpacing: "0.05em",
          fontSize: "30px",
          padding: "5px 0",
        }}
      >
        {time}
      </Typography>
      <Typography
        variant="h6"
        component="p"
        sx={{
          letterSpacing: "0.1em",
          fontSize: "12px",
        }}
      >
        {date}
      </Typography>
    </Box>
  );
};

export default DigitalClock;
