import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Box,
  Typography,
  Snackbar,
  Alert,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "../utils/axiosConfig"; // تأكد من إعداد Axios بشكل صحيح
import ChargeLogsTable from "./ChargeLogsTable"; // مكون جديد لعرض سجلات الشحن
import PaidItemLogsTable from "./PaidItemLogsTable"; // مكون جديد لعرض سجلات العناصر المدفوعة
import RedeemedCouponsTable from "./RedeemedCouponsTable"; // مكون جديد لعرض سجلات الأكواد المستردة

const Logs = () => {
  const [chargeLogs, setChargeLogs] = useState([]);
  const [paidItemLogs, setPaidItemLogs] = useState([]);
  const [redeemedCoupons, setRedeemedCoupons] = useState([]); // حالة جديدة لسجلات الأكواد المستردة
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTable, setSelectedTable] = useState("chargeLogs"); // الحالة لتحديد الجدول الحالي

  const fetchChargeLogs = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/logs/chargeLog?searchTerm=${searchTerm}`
      );
      setChargeLogs(response.data);
    } catch (error) {
      setSnackbarMessage("فشل تحميل سجلات الشحن.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  }, [searchTerm]);

  const fetchPaidItemLogs = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/logs/paidItemLog?searchTerm=${searchTerm}`
      );
      setPaidItemLogs(response.data);
    } catch (error) {
      setSnackbarMessage("فشل تحميل سجلات العناصر المدفوعة.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  }, [searchTerm]);

  const fetchRedeemedCoupons = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/logs/redeemedCoupons?searchTerm=${searchTerm}`
      );
      setRedeemedCoupons(response.data); // تحديث الحالة بسجلات الأكواد المستردة
    } catch (error) {
      setSnackbarMessage("فشل تحميل سجلات الأكواد المستردة.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchChargeLogs();
    fetchPaidItemLogs();
    fetchRedeemedCoupons(); // إضافة استدعاء الدالة الجديدة
  }, [searchTerm, fetchChargeLogs, fetchPaidItemLogs, fetchRedeemedCoupons]);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleTableChange = (event) => {
    setSelectedTable(event.target.value);
  };

  return (
    <Container maxWidth="lg" style={{ direction: "rtl" }}>
      {/* قائمة اختيار نوع الجدول */}
      <Box sx={{ mb: 2 }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>اختر نوع السجل</InputLabel>
          <Select value={selectedTable} onChange={handleTableChange}>
            <MenuItem value="chargeLogs">سجلات الشحن</MenuItem>
            <MenuItem value="paidItemLogs">سجلات مبيعات المتجر</MenuItem>
            <MenuItem value="redeemedCoupons">
              سجلات الأكواد المستردة
            </MenuItem>{" "}
            {/* خيار جديد */}
          </Select>
        </FormControl>
      </Box>

      {/* قسم سجلات الشحن */}
      {selectedTable === "chargeLogs" && (
        <Box
          sx={{
            p: 3,
            bgcolor: "#ffffff",
            borderRadius: 1,
            boxShadow: 1,
            mb: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            سجلات الشحن
          </Typography>
          <TextField
            label="ابحث عن سجل الشحن"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: "250px", mb: 2 }}
          />
          <ChargeLogsTable logs={chargeLogs} />
        </Box>
      )}

      {/* قسم سجلات العناصر المدفوعة */}
      {selectedTable === "paidItemLogs" && (
        <Box
          sx={{
            p: 3,
            bgcolor: "#ffffff",
            borderRadius: 1,
            boxShadow: 1,
            mb: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            سجلات العناصر المدفوعة
          </Typography>
          <TextField
            label="ابحث عن سجل العناصر المدفوعة"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: "250px", mb: 2 }}
          />
          <PaidItemLogsTable logs={paidItemLogs} />
        </Box>
      )}

      {/* قسم سجلات الأكواد المستردة */}
      {selectedTable === "redeemedCoupons" && (
        <Box
          sx={{
            p: 3,
            bgcolor: "#ffffff",
            borderRadius: 1,
            boxShadow: 1,
            mb: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            سجلات الأكواد المستردة
          </Typography>
          <TextField
            label="ابحث عن سجل الكوبونات المستردة"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: "250px", mb: 2 }}
          />
          <RedeemedCouponsTable logs={redeemedCoupons} />{" "}
          {/* استخدام الجدول الجديد */}
        </Box>
      )}

      {/* Snackbar لعرض الرسائل */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Logs;
