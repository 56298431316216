// FailurePage.js
import React from "react";
import { Container, Typography, Button, Box, Paper } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const FailurePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const errorMessage = new URLSearchParams(location.search).get("errorMessage");

  const handleGoBackToRecharge = () => {
    navigate("/charge");
  };

  const handleGoToHome = () => {
    navigate("/");
  };

  return (
    <Container maxWidth="sm" style={{ padding: "20px",height:"90%" }}>
      <Paper elevation={3} style={{ padding: "20px", borderRadius: "8px" }}>
        <Typography variant="h4" gutterBottom align="center" color="error.main">
          Payment Failed
        </Typography>
        <Typography variant="body1" gutterBottom align="center">
          {errorMessage || "An unknown error occurred."}
        </Typography>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoBackToRecharge}
            style={{ marginRight: "10px" }}
          >
            Go Back to Recharge
          </Button>
          <Button variant="outlined" color="primary" onClick={handleGoToHome}>
            Go to Home
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default FailurePage;
