import React, { useState, useEffect } from "react";
import axios from "../utils/axiosConfig";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  Modal,
  CircularProgress,
  Button,
  Container,
} from "@mui/material";

import "../i18n";
import { useTranslation } from "react-i18next";

const News = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get("/api/news");
        setNews(response.data);
      } catch (err) {
        setError("حدث خطأ أثناء تحميل الأخبار.");
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const handleCardClick = (newsItem) => {
    setSelectedNews(newsItem);
  };

  const handleClose = () => {
    setSelectedNews(null);
  };

  if (loading) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(75, 0, 130, 0.5)",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(75, 0, 130, 0.5)",
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <div dir="rtl">
      <Container>
        <Box
          sx={{
            height: "75vh",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "12px",
            },
            "&::-webkit-scrollbar-track": {
              background: "rgba(255, 215, 0, 0.2)", // خلفية شفافة باللون الذهبي
            },
            "&::-webkit-scrollbar-thumb": {
              background: "gold", // اللون الذهبي لمقبض التمرير
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "darkgoldenrod", // لون أغمق لمقبض التمرير عند التمرير
            },
          }}
        >
          <div
            style={{ backgroundColor: "rgba(75, 0, 130, 0.5)", margin: "5px" }}
          >
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              textAlign={"center"}
            >
              {t("آخر الأخبار")}
            </Typography>
            {news.map((item) => (
              <Card
                key={item.id}
                sx={{
                  marginBottom: 1,
                  backgroundColor: "rgba(75, 0, 130, 0.9)",
                }}
                onClick={() => handleCardClick(item)}
                style={{ cursor: "pointer" }}
              >
                <CardContent>
                  <Typography variant="h6" component="div">
                    {t(item.title)}
                  </Typography>
                  <Divider sx={{ backgroundColor: "#FFD700" }} />
                  <Typography variant="body2" color="white" marginTop={1}>
                    {t(item.details)}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>

          <Modal
            open={Boolean(selectedNews)}
            onClose={handleClose}
            aria-labelledby="news-modal-title"
            aria-describedby="news-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                maxWidth: 600,
                bgcolor: "background.paper",
                p: 4,
                borderRadius: 1,
                boxShadow: 24,
              }}
            >
              {selectedNews && (
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <Typography id="news-modal-title" variant="h5" component="h2">
                    {t(selectedNews.title)}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography id="news-modal-description" variant="body1">
                    {t(selectedNews.body)}
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClose}
                    >
                      {t("إغلاق")}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>
        </Box>
      </Container>
    </div>
  );
};

export default News;
