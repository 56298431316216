import React, { useState, useEffect } from "react";
import { Box, Avatar, Container, Typography, Grid, Link } from "@mui/material";
import TermsModal from "./TermsModal"; // تأكد من المسار الصحيح
import axios from "../utils/axiosConfig";
import "../i18n";
import { useTranslation } from "react-i18next";
function Footer() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    const checkTokenValidity = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      try {
        // محاولة التحقق من صلاحية التوكن
        await axios.get("/api/auth", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // التوكن صالح، لا حاجة للقيام بشيء هنا
      } catch (error) {
        console.error("Error checking token validity:", error);

        // التحقق إذا كان الخطأ نتيجة انتهاء صلاحية التوكن
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.message === "Token has expired"
        ) {
          // حذف التوكن وبيانات المستخدم من localStorage
          localStorage.removeItem("token");
          localStorage.removeItem("userData");

          // إعادة توجيه المستخدم إلى صفحة تسجيل الدخول أو إظهار رسالة تنبيه
          console.log("Token expired. Redirecting to login page...");
          window.location.href = "/login";
        }
      }
    };

    checkTokenValidity();
  }, []);

  return (
    <Box
      sx={{
        minHeight: "40vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box component="main" sx={{ flex: 1 }}>
        {/* Main content here */}
      </Box>

      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          color: "white",
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            {/* القسم الأول */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" gutterBottom>
                {t("عن السيرفر")}
              </Typography>
              <Typography variant="body2">
                {t("سيرفر أوديسي حيث لا مكان للملل")}
              </Typography>
            </Grid>

            {/* القسم الثاني */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Link
                href="https://www.elitepvpers.com/forum/rappelz-private-server/"
                target="_blank"
              >
                <Avatar
                  alt="elitepvpers"
                  src="https://www.elitepvpers.com/forum/images/elitepvpers/statusicon/forum_new.gif"
                  sx={{ width: 56, height: 56 }}
                />
              </Link>
              <Typography
                variant="body2"
                sx={{ mt: 2, cursor: "pointer" }}
                onClick={handleOpenModal}
              >
                {t("سياسة الاستخدام وقوانين السيرفر")}
              </Typography>
            </Grid>

            {/* القسم الثالث */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                style={{ textAlign: "center" }}
              >
                {t("تواصل معنا")}
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                {/* Facebook */}
                <Grid item>
                  <Link
                    href="https://www.facebook.com/share/15HhPYExPC/"
                    target="_blank"
                  >
                    <Avatar
                      alt="Facebook"
                      src="/images/facebook.jpg"
                      sx={{ width: 36, height: 36 }}
                    />
                  </Link>
                </Grid>
                {/* Twitter */}
                <Grid item>
                  <Link href="https://discord.gg/YBAzVcn4FU" target="_blank">
                    <Avatar
                      alt="discoord"
                      src="/images/discoord.png"
                      sx={{ width: 36, height: 36 }}
                    />
                  </Link>
                </Grid>
                {/* youtube */}
                <Grid item>
                  <Link
                    href="https://www.youtube.com/@OdysseyRappelz"
                    target="_blank"
                  >
                    <Avatar
                      alt="youtube"
                      src="/images/youtube.png"
                      sx={{ width: 36, height: 36 }}
                    />
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://t.me/+o7rMxD6OU2djYjJk" target="_blank">
                    <Avatar
                      alt="Telegram"
                      src="/images/Telegram.png"
                      sx={{ width: 36, height: 36 }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              ALL COPYRIGHTS RESERVED TO Odyssey SERVER 2024 ©
            </Typography>
          </div>
        </Container>

        {/* Modal for Terms and Policies */}
        <TermsModal open={openModal} onClose={handleCloseModal} />
      </Box>
    </Box>
  );
}

export default Footer;
