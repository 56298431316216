import React, { useEffect, useState } from "react";
import {
  Typography,
  CardContent,
  Grid,
  Container,
  Paper,
  Button,
} from "@mui/material";
import axios from "../utils/axiosConfig";

const RechargePage = () => {
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get("/api/charge");
        setOffers(response.data);
      } catch (error) {
        console.error("Error fetching recharge offers:", error);
      }
    };

    fetchOffers();
  }, []);

  const handleRecharge = async (amount, points, retryCount = 3) => {
    setLoading(true);
    setButtonDisabled(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post("/api/payment", {
        amount,
        points,
        token,
      });
      window.location.href = response.data.approvalUrl;
    } catch (error) {
      if (error.response && error.response.status === 500 && retryCount > 0) {
        console.log(`Retrying... ${3 - retryCount + 1}`);
        setTimeout(() => handleRecharge(amount, points, retryCount - 1), 1000);
      } else {
        console.error("Error during recharge:", error);
        alert("Recharge failed. Please try again later.");
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
        setButtonDisabled(false);
      }, 2000);
    }
  };

  const getCardStyle = () => ({
    background: "linear-gradient(135deg, #4B0082, #8A2BE2)", // Dark Purple Gradient
    color: "#FFF",
    boxShadow: "0 0 20px rgba(75, 0, 130, 0.5)",
    borderRadius: "8px",
  });

  const goldenTextStyle = {
    color: "#FFD700", // Light gold color
  };

  return (
    <Container maxWidth="lg" style={{ padding: "20px", height: "90%" }}>
      <Paper elevation={3} style={{ padding: "20px", borderRadius: "8px" }}>
        <Typography variant="h4" gutterBottom align="center" color="primary">
          Recharge Your Points
        </Typography>
        <Grid container spacing={2}>
          {offers.map((offer) => (
            <Grid item xs={12} sm={6} md={4} key={offer.ID}>
              <div
                style={{
                  ...getCardStyle(),
                  padding: "20px",
                  textAlign: "center",
                  cursor: loading || buttonDisabled ? "not-allowed" : "pointer",
                  transition: "transform 0.3s, box-shadow 0.3s",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h3"
                    component="div"
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    ${offer.Price}
                  </Typography>
                  <Typography
                    variant="h6"
                    align="center"
                    style={goldenTextStyle}
                  >
                    Get {offer.Points} points
                  </Typography>
                  {offer.ItemCode && (
                    <Typography
                      variant="body2"
                      align="center"
                      style={{
                        marginTop: "8px",
                        fontWeight: "bold",
                        ...goldenTextStyle,
                      }}
                    >
                      {offer.ItemCode}
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    style={{ marginTop: "15px", fontWeight: "bold" }}
                    onClick={() =>
                      !loading &&
                      !buttonDisabled &&
                      handleRecharge(offer.Price, offer.Points)
                    }
                    disabled={loading || buttonDisabled}
                  >
                    Recharge Now!
                  </Button>
                </CardContent>
              </div>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

export default RechargePage;
