import React, { useState, useEffect } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Toolbar,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "../utils/axiosConfig"; // تأكد من تحديث المسار بناءً على مكان وجود axiosConfig
import "../i18n";
import { useTranslation } from "react-i18next";

const Navbar = ({
  categories,
  selectedTab,
  handleTabChange,
  handleCartOpen,
  onUserDataFetched, // تمرير دالة لتحديث بيانات المستخدم في الصفحة الرئيسية
}) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [name, setName] = useState("");
  const [points, setPoints] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("Token not found in localStorage");
        setLoading(false);
        return;
      }

      try {
        const userResponse = await axios.get("/api/checkAuth", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        localStorage.setItem("userData", JSON.stringify(userResponse.data));
        setName(userResponse.data.account);
        setPoints(userResponse.data.point);

        // تمرير بيانات المستخدم إلى الصفحة الرئيسية إذا كانت الدالة موجودة
        if (onUserDataFetched) {
          onUserDataFetched(userResponse.data.account, userResponse.data.point);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [onUserDataFetched]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const { t } = useTranslation();
  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    // الحصول على اللغة من localStorage
    const storedLanguage = localStorage.getItem("i18nextLng");

    // تعيين الاتجاه بناءً على اللغة
    if (storedLanguage === "ar") {
      setDirection("rtl");
    } else {
      setDirection("ltr");
    }
  }, []);

  return (
    <AppBar position="static" color="default" style={{direction:direction}}>
      <Toolbar>
        {/* عرض القائمة عند الشاشات الصغيرة */}
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {categories.map((category, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleTabChange(null, index);
                  handleCloseNavMenu();
                }}
              >
                {category.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>

        {/* عرض التبويبات عند الشاشات الأكبر */}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {categories.map((category, index) => (
              <Tab key={index} label={category.name} />
            ))}
          </Tabs>
        </Box>

        {/* عرض بيانات المستخدم */}
        <Box display="flex" flexDirection="column" alignItems="center" ml={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {name || "username"}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {t("النقاط")} : {points !== null ? points : "unavailable"}
              </Typography>
            </>
          )}
        </Box>

        {/* زر عرض السلة */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCartOpen}
          sx={{ ml: 2 }}
        >
          {t("عرض السلة")}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
