import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "../utils/axiosConfig"; // تأكد من المسار الصحيح

const CouponComponent = () => {
  const [coupons, setCoupons] = useState([]); // بيانات الكوبونات
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const [editedCode, setEditedCode] = useState(""); // Initialize with an empty string
  const [editedReward, setEditedReward] = useState(""); // Initialize with an empty string
  const [editedDaysValid, setEditedDaysValid] = useState(""); // Initialize with an empty string
  const [editedUsageLimit, setEditedUsageLimit] = useState(""); // Initialize with an empty string
  const [editedIsActive, setEditedIsActive] = useState(true);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newCode, setNewCode] = useState(""); // Initialize with an empty string
  const [newReward, setNewReward] = useState(""); // Initialize with an empty string
  const [newDaysValid, setNewDaysValid] = useState(""); // Initialize with an empty string
  const [newUsageLimit, setNewUsageLimit] = useState(""); // Initialize with an empty string

  // جلب الكوبونات من الخادم
  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await axios.get("/api/coupons/get-coupons");
        // تحقق إذا كانت البيانات هي مصفوفة
        if (Array.isArray(response.data.coupons)) {
          setCoupons(response.data.coupons);
        } else {
          setCoupons([]); // إذا كانت البيانات غير صحيحة، نعيد المصفوفة الفارغة
        }
      } catch (error) {
        setSnackbarMessage("فشل تحميل الكوبونات.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      }
    };
    fetchCoupons();
  }, []);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleOpenEditDialog = (coupon) => {
    setSelectedCoupon(coupon);
    setEditedCode(coupon.code || ""); // Default to empty string if null
    setEditedReward(coupon.reward || ""); // Default to empty string if null
    setEditedDaysValid(coupon.daysValid || ""); // Default to empty string if null
    setEditedUsageLimit(coupon.usage_limit || ""); // Default to empty string if null
    setEditedIsActive(coupon.is_active);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedCoupon(null);
  };

  const handleEditSubmit = async () => {
    if (selectedCoupon) {
      try {
        const response = await axios.put(
          `/api/coupons/update-coupon/${selectedCoupon.id}`,
          {
            code: editedCode,
            reward: editedReward,
            daysValid: editedDaysValid,
            usageLimit: editedUsageLimit,
            isActive: editedIsActive,
          }
        );
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("success");
        setShowSnackbar(true);
        // تحديث قائمة الكوبونات بعد التعديل
        const updatedCoupons = await axios.get("/api/coupons/get-coupons");
        if (Array.isArray(updatedCoupons.data)) {
          setCoupons(updatedCoupons.data);
        }
      } catch (error) {
        setSnackbarMessage("فشل تعديل الكوبون.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      }
    }
    handleCloseEditDialog();
  };

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    // إعادة تعيين الحقول
    setNewCode(""); // Reset to empty string
    setNewReward(""); // Reset to empty string
    setNewDaysValid(""); // Reset to empty string
    setNewUsageLimit(""); // Reset to empty string
  };

  const handleCreateSubmit = async () => {
    try {
      const response = await axios.post("/api/coupons/create-coupon", {
        code: newCode,
        reward: newReward,
        daysValid: newDaysValid,
        usageLimit: newUsageLimit,
      });
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      // تحديث قائمة الكوبونات بعد الإنشاء
      const updatedCoupons = await axios.get("/api/coupons/get-coupons");
      if (Array.isArray(updatedCoupons.data)) {
        setCoupons(updatedCoupons.data);
      }
    } catch (error) {
      setSnackbarMessage("فشل إنشاء الكوبون.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
    handleCloseCreateDialog();
  };

  const handleDeleteCoupon = async (couponId) => {
    try {
      const response = await axios.delete(
        `/api/coupons/delete-coupon/${couponId}`
      );
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      // تحديث قائمة الكوبونات بعد الحذف
      const updatedCoupons = await axios.get("/api/coupons/get-coupons");
      if (Array.isArray(updatedCoupons.data)) {
        setCoupons(updatedCoupons.data);
      }
    } catch (error) {
      setSnackbarMessage("فشل حذف الكوبون.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };

  return (
    <Container maxWidth="lg" style={{ direction: "rtl" }}>
      <Box
        sx={{ p: 3, bgcolor: "#ffffff", borderRadius: 1, boxShadow: 1, mb: 2 }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" gutterBottom>
            إدارة الكوبونات
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCreateDialog}
            sx={{ mb: 2 }}
          >
            إنشاء كوبون جديد
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: "#5B0052" }}>
              <TableRow>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  ID
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الكود
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  المكافأة
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  صلاحيته (أيام)
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الحد الأقصى للاستخدام
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  عدد الاستخدامات
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الإجراءات
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons.length > 0 ? (
                coupons.map((coupon) => (
                  <TableRow key={coupon.id}>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.id}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.code}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.reward}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.daysValid === null
                        ? "غير محدود"
                        : coupon.daysValid}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.usage_limit === null
                        ? "غير محدود"
                        : coupon.usage_limit}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.times_redeemed}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenEditDialog(coupon)}
                      >
                        تعديل
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteCoupon(coupon.id)}
                      >
                        حذف
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    لا توجد كوبونات لعرضها
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Snackbar for notifications */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={snackbarSeverity} onClose={handleCloseSnackbar}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Edit Coupon Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>تعديل الكوبون</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="الكود"
            value={editedCode}
            onChange={(e) => setEditedCode(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="المكافأة"
            value={editedReward}
            onChange={(e) => setEditedReward(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="عدد الأيام الصالحة"
            value={editedDaysValid}
            onChange={(e) => setEditedDaysValid(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="حد الاستخدام"
            value={editedUsageLimit}
            onChange={(e) => setEditedUsageLimit(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editedIsActive}
                onChange={(e) => setEditedIsActive(e.target.checked)}
              />
            }
            label="هل الكوبون مفعل؟"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            إلغاء
          </Button>
          <Button onClick={handleEditSubmit} color="primary">
            حفظ التعديلات
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Coupon Dialog */}
      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
        <DialogTitle>إنشاء كوبون جديد</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="الكود"
            value={newCode}
            onChange={(e) => setNewCode(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="المكافأة"
            value={newReward}
            onChange={(e) => setNewReward(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="عدد الأيام الصالحة"
            value={newDaysValid}
            onChange={(e) => setNewDaysValid(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="حد الاستخدام"
            value={newUsageLimit}
            onChange={(e) => setNewUsageLimit(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog} color="primary">
            إلغاء
          </Button>
          <Button onClick={handleCreateSubmit} color="primary">
            إنشاء كوبون
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CouponComponent;
