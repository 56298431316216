import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Box,
  Typography,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import axios from "../utils/axiosConfig";

const itemNames = {
  806809: "بطاقة 100ن متجر",
  57001004: "بطاقة 10 نقطة ميدان",
  57001000: "بطاقة 50 نقطة ميدان",
  57001001: "بطاقة 100 نقطة ميدان",
  57001002: "بطاقة 500 نقطة ميدان",
  57001003: "بطاقة 1000 نقطة ميدان",
  57001005: "Odyssey 1K Card",
  57001006: "Odyssey 10K Card",
  57001007: "Odyssey 50K Card",
  57001008: "Odyssey 100K Card",
  57001009: "Odyssey 500K Card",
  57001010: "Odyssey 1M Card",
  602702: "قالب فضي",
  602701: "قالب ذهبي",
  806815: "بطاقة 50 نقطة متجر",
};

const ItemDisplay = () => {
  const [items, setItems] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [details, setDetails] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [itemCode, setItemCode] = useState("");

  const fetchData = useCallback(async (code = "") => {
    try {
      const response = await axios.get("/api/itemsState", {
        params: { code },
      });
      const combinedData = combineData(
        response.data.characters,
        response.data.accounts
      );
      setItems(combinedData);
    } catch (error) {
      setSnackbarMessage("فشل تحميل البيانات.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const combineData = (characters, accounts) => {
    const combined = {};

    characters.forEach((character) => {
      const code = character.code;
      if (!combined[code]) {
        combined[code] = {
          code,
          name: itemNames[code] || "غير معروف",
          charactersCount: 0,
          accountsCount: 0,
          charactersOwnersCount: new Set(),
          accountsOwnersCount: new Set(),
          characters: [],
          accounts: [],
        };
      }
      combined[code].charactersCount += Number(character.cnt);
      combined[code].charactersOwnersCount.add(character.owner_id);
      combined[code].characters.push(character);
    });

    accounts.forEach((account) => {
      const code = account.code;
      if (!combined[code]) {
        combined[code] = {
          code,
          name: itemNames[code] || "غير معروف",
          charactersCount: 0,
          accountsCount: 0,
          charactersOwnersCount: new Set(),
          accountsOwnersCount: new Set(),
          characters: [],
          accounts: [],
        };
      }
      combined[code].accountsCount += Number(account.cnt);
      combined[code].accountsOwnersCount.add(account.owner_id);
      combined[code].accounts.push(account);
    });

    Object.values(combined).forEach((item) => {
      item.charactersOwnersCount = item.charactersOwnersCount.size;
      item.accountsOwnersCount = item.accountsOwnersCount.size;
    });

    return Object.values(combined);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleRowClick = (data) => {
    setDetails(data);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDetails([]);
  };

  const handleSearch = () => {
    fetchData(itemCode);
  };

  return (
    <Container
      maxWidth="lg"
      style={{ direction: "rtl", display: "flex", justifyContent: "center" }}
    >
      <Box
        sx={{
          p: 3,
          bgcolor: "#ffffff",
          borderRadius: 1,
          boxShadow: 1,
          mb: 2,
          width: "100%",
        }}
      >
        <Typography variant="h4" gutterBottom textAlign="center">
          عرض بيانات العناصر
        </Typography>

        <TextField
          label="أدخل كود العنصر"
          value={itemCode}
          onChange={(e) => setItemCode(e.target.value)}
          variant="outlined"
          fullWidth
          style={{ marginBottom: "16px" }}
        />
        <Button variant="contained" onClick={handleSearch}>
          بحث
        </Button>

        <Typography
          variant="h6"
          textAlign="center"
          style={{ marginTop: "16px" }}
        >
          جدول العناصر
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: "#5B0052" }}>
              <TableRow>
                <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                  الكود
                </TableCell>
                <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                  اسم العنصر
                </TableCell>
                <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                  إجمالي العدد في الشخصيات (عدد المالكون: عدد)
                </TableCell>
                <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                  إجمالي العدد في الحسابات (عدد المالكون: عدد)
                </TableCell>
                <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                  إجمالي العدد
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.code}>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.code}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.name}
                  </TableCell>
                  <TableCell
                    onClick={() => handleRowClick(item.characters)}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    {`${item.charactersCount} (${item.charactersOwnersCount})`}
                  </TableCell>
                  <TableCell
                    onClick={() => handleRowClick(item.accounts)}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    {`${item.accountsCount} (${item.accountsOwnersCount})`}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.charactersCount + item.accountsCount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* نافذة تفاصيل العناصر */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>تفاصيل العناصر</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{ background: "#5B0052" }}>
                <TableRow>
                  <TableCell style={{ color: "#ffffff" }}>SID</TableCell>
                  <TableCell style={{ color: "#ffffff" }}>Owner ID</TableCell>
                  <TableCell style={{ color: "#ffffff" }}>Account ID</TableCell>
                  <TableCell style={{ color: "#ffffff" }}>Code</TableCell>
                  <TableCell style={{ color: "#ffffff" }}>Count</TableCell>
                  <TableCell style={{ color: "#ffffff" }}>
                    Create Time
                  </TableCell>
                  <TableCell style={{ color: "#ffffff" }}>
                    Update Time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.map((item) => (
                  <TableRow key={item.sid}>
                    <TableCell>{item.sid}</TableCell>
                    <TableCell>{item.owner_id}</TableCell>
                    <TableCell>{item.account_id}</TableCell>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.cnt}</TableCell>
                    <TableCell>
                      {new Date(item.create_time).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {new Date(item.update_time).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>إغلاق</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ItemDisplay;
